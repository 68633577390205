import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { BsLocaleService, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SessionService } from '@parashift/shared/services';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

type Placement = 'left' | 'right' | 'top' | 'bottom';

@Component({
    selector: 'datepicker-type',
    templateUrl: './datepicker.component.html',
    imports: [FaIconComponent, BsDatepickerModule, ReactiveFormsModule, FormlyModule]
})
export class DatepickerComponent extends FieldType<FieldTypeConfig> {

  get datepickerFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  get placement(): Placement {
    return (this.props.placement || 'bottom') as Placement;
  }

  constructor(private localeService: BsLocaleService, protected sessionService: SessionService) {
    super();
    this.localeService.use(this.sessionService.user.language);
  }
}
