import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FieldWrapper } from '@ngx-formly/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CheckPermissionsDirective } from '@parashift/shared/directives';

@Component({
  selector: 'card-wrapper',
  templateUrl: './card-wrapper.component.html',
  imports: [NgClass, CheckPermissionsDirective, NgIf, FaIconComponent, CollapseModule]
})
export class CardWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;

  defaultOptions = {
    props: {
      class: '',
      isCollapsible: false,
      showErrors: '',
      saveButton: false,
      permissions: {} as '{ roles: string[]; permissions: string[] }'
    }
  };

  isCollapsed = false;
  cardFormControl;

  ngOnInit() {
    if (this.props.isCollapsible) {
      this.isCollapsed = this.props.isCollapsed || false;
    }

    if (this.props.showErrors) {
      this.cardFormControl = this.form.get(this.props.showErrors);
    }
  }
}
