import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-checkbox-switch',
    templateUrl: './switch.component.html',
    imports: [ReactiveFormsModule, FormlyModule, NgIf]
})
export class SwitchComponent extends FieldType<FieldTypeConfig> {
  defaultOptions = {
    props: {
      hideLabel: true
    }
  };

  get switchFormControl() {
    return this.formControl as FormControl;
  }
}
