import { AfterViewInit, Component, DestroyRef, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { forkJoin, merge, Observable, Observer } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DocumentTypeField, DocumentTypeFieldStatus, Type, DocumentType, FilterConfig, ListFilter } from '@parashift/shared/models';
import { BusinessSectorsRegister, DocumentTypeFieldService, ErrorResponse, JsonApiQueryData, ListFilterService, LocalesRegister, Logger, NotificationService, TagsRegister } from '@parashift/shared/services';
import { ListResultCounterDirective, ResultSwitchDirective } from '@parashift/shared/directives';
import { AvailableModels, translateModelTitles } from '@parashift/shared/utils';
import { filterColumnConfigBuilder } from './filter-column.config';
import { FilterColumnComponent } from 'shared/components/filter-column/filter-column.component';
import { EmptyResultDescriptionComponent } from 'shared/components/empty-result-description/empty-result-description.component';
import { TagBadgesComponent } from 'shared/components/badges/tag-badges/tag-badges.component';
import { BusinessSectorBadgesComponent } from 'shared/components/badges/business-sector-badges/business-sector-badges.component';
import { LocaleBadgesComponent } from 'shared/components/badges/locale-badges/locale-badges.component';
import { FilterSortingComponent } from 'shared/components/filter-sorting/filter-sorting.component';
import { FilterTagsComponent } from 'shared/components/filter-tags/filter-tags.component';
import { LeftColumnComponent } from 'shared/components/columns/left-column/left-column.component';

@Component({
  selector: 'pdc-add-fields-modal',
  templateUrl: './add-fields-modal.component.html',
  imports: [
    BusinessSectorBadgesComponent,
    EmptyResultDescriptionComponent,
    FaIconComponent,
    FilterColumnComponent,
    FilterSortingComponent,
    FilterTagsComponent,
    FormsModule,
    LeftColumnComponent,
    ListResultCounterDirective,
    LocaleBadgesComponent,
    NgFor,
    NgIf,
    PaginationModule,
    ReactiveFormsModule,
    ResultSwitchDirective,
    TagBadgesComponent,
    TooltipModule,
  ]
})
export class AddFieldsModalComponent implements AfterViewInit, OnInit {
  @ViewChild(FilterColumnComponent) filterComponent: FilterColumnComponent;
  filterColumnConfig: FilterConfig;

  selectedFields: DocumentTypeField[] = [];
  createdFieldIds: string[] = [];
  state: boolean;
  documentTypeFields: JsonApiQueryData<DocumentTypeField>;
  listFilter: ListFilter;
  documentTypeFieldStatus = DocumentTypeFieldStatus;
  tenant_id: number;
  excludedFieldIds = [];
  selectedFieldIds = [];
  fieldsetFields = false;
  type = Type;
  data: {
    fieldset_fields?: boolean;
    documentType?: DocumentType;
    fields?: DocumentTypeField[];
  } = {};
  disableButtons = false;

  constructor(
    public bsModalRef: BsModalRef,
    private documentTypeFieldService: DocumentTypeFieldService,
    private listFilterService: ListFilterService,
    public localesRegister: LocalesRegister,
    public businessSectorsRegister: BusinessSectorsRegister,
    public tagsRegister: TagsRegister,
    private notificationService: NotificationService,
    private logger: Logger,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {
    this.filterColumnConfig = filterColumnConfigBuilder(this.localesRegister, this.businessSectorsRegister, this.tagsRegister);
  }

  ngOnInit() {
    this.initListFilter();
    this.getDocumentTypeFields();
  }

  ngAfterViewInit() {
    this.filterComponent.$filterParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(400)
      )
      .subscribe(listFilter => {
        this.listFilter = listFilter as ListFilter;
        this.getDocumentTypeFields();
      });
  }

  handleFieldSelection(event: any, field: DocumentTypeField) {
    if (event.target.checked) {
      this.addField(field);
    } else {
      this.removeField(field);
    }
  }

  addSelectedFields() {
    this.disableButtons = true;
    this.copyPublicFields().subscribe({
      next: () => {
        this.state = true;
        this.bsModalRef.content.model = this.selectedFields;
        this.bsModalRef.hide();
      },
      error: () => {
        this.notificationService.error({
          description: $localize `:@@shared_pdc.components.add_fields_modal.copy_fields_error.description:An error occured while trying to copy fields. Please try again`,
          title: $localize `:@@shared_pdc.components.add_fields_modal.copy_fields_error.title:Error`
        });

        this.deleteCreatedFieldsOnError();
        this.disableButtons = false;
      }
    });
  }

  close() {
    this.bsModalRef.hide();
  }

  alternativeAction() {
    if (this.bsModalRef.content && this.bsModalRef.content.action_alternative) {
      this.bsModalRef.content.action_alternative();
    }
    this.bsModalRef.hide();
  }

  getModelTitle(modelTitle: string, quantity: number) {
    return translateModelTitles(modelTitle as AvailableModels, quantity);
  }

  private initListFilter() {
    this.listFilter = this.listFilterService.getStoredFilters(
      this.filterColumnConfig.meta.identifier,
      {
        filter: { extractor_attribute_blank: this.fieldsetFields ? 'false' : true },
        stats: { total: ['count'] },
        include: 'parent'
      },
      true
    );
  }

  private getDocumentTypeFields() {
    this.documentTypeFieldService.findAll(this.listFilter, this.tenant_id).subscribe({
      next: (documentTypeFields: JsonApiQueryData<DocumentTypeField>) => {
        this.setExcludedFields();
        this.documentTypeFields = documentTypeFields;
      },
      error: (error: ErrorResponse) => {
        this.logger.log('add-fields-modal-component', 'DOCUMENT TYPE FIELDS Error', error);
      }
    });
  }

  private setExcludedFields() {
    if (
      this.data.documentType
      && this.data.documentType.document_type_relations
      && Array.isArray(this.data.documentType.document_type_relations)
      && this.excludedFieldIds.length === 0
    ) {
      this.data.documentType.document_type_relations.forEach(relation => {
        this.excludedFieldIds.push(String(relation.document_type_field_id));
      });
    } else if (
      this.data.fields
      && Array.isArray(this.data.fields)
      && this.excludedFieldIds.length === 0
    ) {
      this.data.fields.forEach(field => {
        this.excludedFieldIds.push(String(field.id));
      });
    }
  }

  private addField(field: DocumentTypeField) {
    this.selectedFields.push(field);
    this.selectedFieldIds.push(field.id);
  }

  private removeField(field: DocumentTypeField) {
    const index = this.selectedFields.findIndex(dtf => dtf.id === field.id);

    if (index > -1) {
      this.selectedFields.splice(index, 1);
      this.selectedFieldIds.splice(index, 1);
    }
  }

  private deleteCreatedFieldsOnError() {
    if (this.createdFieldIds.length > 0) {
      const requests = [];

      this.createdFieldIds.forEach(id => {
        const request = this.documentTypeFieldService.deleteRecord(id, this.tenant_id);
        requests.push(request);
      });

      const multiRequests = merge(...requests);
      multiRequests.subscribe();
      this.createdFieldIds = [];
    }
  }

  private copyPublicFields(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const requests: Observable<DocumentTypeField>[] = [];
      this.selectedFields.forEach(field => {
        if (field.tenant_id !== this.tenant_id) {
          const changedAttributes = {
            visibility: 'tenant'
          };

          const request = this.documentTypeFieldService.inheritFrom(field.id, this.tenant_id, changedAttributes).pipe(tap(newField => {
            this.createdFieldIds.push(newField.id);
          }));

          requests.push(request);
        }
      });

      if (requests.length > 0) {
        const multiRequests = forkJoin(requests);
        multiRequests.subscribe({
          next: (fields: DocumentTypeField[]) => {
            fields.forEach(field => {
              const index = this.selectedFields.findIndex(selectedField => Number(selectedField.id) === field.parent_id);

              if (index > -1) {
                this.selectedFields[index] = field;
              }
            });

            observer.next(true);
            observer.complete();
          },
          error: () => {
            observer.error(false);
            observer.complete();
          }
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
