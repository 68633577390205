import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@Component({
  selector: 'collapsible-wrapper',
  templateUrl: './collapsible-wrapper.component.html',
  imports: [CollapseModule]
})
export class CollapsibleWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
  isCollapsed = true;

  ngOnInit() {
    this.isCollapsed = this.props.isCollapsed || true;
  }
}
