<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title" i18n="@@shared_pdc.components.add_fieldset_modal.title">Apply fieldset</h6>
  <!-- Title end -->

  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">

  <div class="row">

    <!-- Left Column -->
    <left-column *ngIf="filterColumnConfig" class="col-auto left-column"
                 [identifiers]="['filter']"
                 [icons]="['filter']"
                 #lc>
      <filter-column *ngIf="lc.show === 'filter' || !lc.show"
                     [listFilter]=listFilter
                     [filterConfig]="filterColumnConfig">
      </filter-column>
    </left-column>
    <!-- Left Column end -->

    <!-- Main Column -->
    <div class="col">

      <!-- List & Filters -->
      <div *resultSwitch="documentTypeFieldsets?.getModels().length; listFilter: listFilter; templatePart: 'result'" style="margin-right: -10px;">
          <div class="row mb-3 font-size-base">
            <div class="col-8">
              <filter-tags [filterConfig]="filterColumnConfig" [openOnLoad]="true"></filter-tags>
            </div>
            <div class="col-4">
              <div class="float-end">
                <filter-sorting [listFilter]="listFilter"
                                [filterConfig]="filterColumnConfig"
                ></filter-sorting>
              </div>
            </div>
          </div>

          <!-- Result -->
          <div *ngIf="documentTypeFieldsets?.getModels().length > 0; else no_result">
            <div class="table-responsive modal-scroll-container">
              <table class="table">
                <thead class="table-dark">
                  <tr>
                    <th i18n="@@common.name">Name</th>
                    <th i18n="@@common.fields">Fields</th>
                    <th i18n="@@common.description">Description</th>
                    <th i18n="@@common.issuer">Issuer</th>
                    <th i18n="@@common.type">Type</th>
                    <th i18n="@@common.locales">Locales</th>
                    <th i18n="@@common.business_sectors">Business Sectors</th>
                    <th i18n="@@common.tags">Tags</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template ngFor let-documentTypeFieldset [ngForOf]="documentTypeFieldsets?.getModels()" let-index="index">
                    <tr>
                      <td><strong tooltip="{{ '#' + documentTypeFieldset.id + ': ' + documentTypeFieldset.identifier }}">{{ documentTypeFieldset.name }}</strong></td>
                      <td><span *ngFor="let field of fieldsetFields[documentTypeFieldset.id]" class="badge rounded-pill text-bg-light me-1">{{ field }}</span></td>

                      <td>
                        <fa-icon [icon]="['fas', 'exclamation-circle']"
                                 [fixedWidth]="true"
                                 *ngIf="documentTypeFieldset.description"
                                 tooltip="{{ documentTypeFieldset.description }}">
                        </fa-icon>
                      </td>
                      <td>{{ documentTypeFieldset.parent.tenant_id || documentTypeFieldset.tenant_id }}</td>
                      <td>{{ type[documentTypeFieldset.getType(tenant_id)] }}</td>
                      <td><locale-badges [locales]="documentTypeFieldset.locales"></locale-badges></td>
                      <td><business-sector-badges [businessSectors]="documentTypeFieldset.business_sectors"></business-sector-badges></td>
                      <td><tag-badges [tags]="documentTypeFieldset.tags"></tag-badges></td>
                      <td>
                        <button type="button"
                                class="btn btn-xs btn-success me-1"
                                (click)="addSelectedFieldset(documentTypeFieldset, fieldSetFieldRelations[documentTypeFieldset.id])"
                                [disabled]="existingFieldsetIds.includes(documentTypeFieldset.id) || disableButtons"
                         i18n="@@shared_pdc.components.add_fieldset_modal.add_fieldset">Add fieldset</button>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-auto">
                <pagination [boundaryLinks]="true"
                            [totalItems]="documentTypeFieldsets?.getMeta().meta.stats.total.count"
                            [itemsPerPage]="20"
                            [maxSize]="10"
                            [ngModel]="listFilter.page.number"
                            (pageChanged)="filterComponent.setPageValue($event)"
                            previousText="&lsaquo;"
                            nextText="&rsaquo;"
                            firstText="&laquo;"
                            lastText="&raquo;"
                ></pagination>
              </div>
              <div class="col">
                <div *ngIf="documentTypeFieldsets?.getMeta().meta.stats.total.count > 0"
                     appListResultCounter
                     [pageNumber]="listFilter.page.number"
                     [pageSize]="20"
                     [totalCount]="documentTypeFieldsets?.getMeta().meta.stats.total.count"
                     modelName="{{ getModelTitle('document_type_fieldset', documentTypeFieldsets?.getMeta().meta.stats.total.count) }}"
                ></div>
              </div>
            </div>
          </div>
          <!-- Result end -->

          <!-- No Result -->
          <ng-template #no_result>
            <empty-result-description model="document_type_fieldset"></empty-result-description>
          </ng-template>
          <!-- No Result end -->

      </div>
      <!-- List & Filters end -->

    </div>
    <!-- Main Column end -->

  </div>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer justify-content-end">
  <div class="text-start">
    <button type="button" class="button-secondary" style="margin-right: 8px;"
            (click)="alternativeAction()"
            [disabled]="disableButtons"
            [checkPermissions]="{ roles: ['admin', 'support', 'sales'], permissions: ['admin', 'owner', 'pdc.configure_doctype', 'pdc.configure_field'] }"
    ><span i18n="@@shared_pdc.components.add_fieldset_modal.create_new_fieldset">Create new fieldset</span></button>
  </div>
  <div class="text-end">
    <button type="button"
            class="button-secondary-outlined"
            (click)="close()"
            [disabled]="disableButtons"
    ><span i18n="@@common.cancel">Cancel</span></button>
  </div>
</div>
<!-- Footer end -->
