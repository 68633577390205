<form [formGroup]="form" *ngIf="form" novalidate>
  <!-- Header -->
  <div class="modal-header">

    <!-- Title -->
    <h6 class="modal-title" i18n="@@shared_pdc.components.add_mdm_config_modal.title">Add Master Data Mapping</h6>
    <!-- Title end -->

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Header end -->

  <!-- Content -->
  <div class="modal-body">
    <div>
      <div class="row max-form-width">
        <div class="col-sm-6">
          <input-field formControlName="name" i18n-label="@@components.add_mdm_config_modal.name" label="Name"></input-field>
        </div>
        <div class="col-sm-6">
          <select-field formControlName="table_name" i18n-label="@@components.add_mdm_config_modal.table_name" label="Table" [options]="tableNameOptions"></select-field>
        </div>
        <div *ngIf="tableColumns.length > 0" formGroupName="field_mapping">
          <div class="col-sm-12">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-6 bold">Table Column</div>
                  <div class="col-sm-6 bold">Type</div>
                </div>
              </div>
              <div class="col-sm-6 bold">Write to Document-Type-Field</div>
            </div>
            <div class="row" *ngFor="let column of tableColumns">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-6"> {{ column.name }}</div>
                  <div class="col-sm-6">{{ column.type }}</div>
                </div>
              </div>
              <div class="col-sm-6">
                <typeahead-field
                  [formControlName]="column.name"
                  label=""
                  type="string"
                  labelProp="label"
                  valueProp="value"
                  icon="pen-field"
                  [search]="searchFields"
                  [translateInitialValueToLabel]="translateInitialFieldValueToLabel"
                  [optionsLimit]="10"
                  [minLength]="0"
                  [isFirstItemActive]="true"
                ></typeahead-field>
              </div>
            </div>
            <div class="row" *ngIf="form.get('field_mapping').hasError('empty-form-group') && (form.get('field_mapping').touched || form.get('field_mapping').dirty)">
              <div class="col-sm-12 danger text-end" i18n="@@shared_pdc.components.add_mdm_config_modal.empty_field_mapping_error">
                At least one field mapping is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content end -->

  <!-- Footer -->
  <div class="modal-footer">
    <button type="button" class="button-secondary-outlined" style="margin-right: 8px" (click)="cancel()" i18n="@@common.cancel">Cancel</button>
    <button type="button" class="button-secondary" (click)="confirm()"><span i18n="@@common.save">Save</span></button>
  </div>
  <!-- Footer end -->
</form>
