import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputFieldType } from 'shared/components/forms/types/input-field-type';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'number-percentage-dirty',
    templateUrl: './number-percentage-dirty.component.html',
    styleUrls: ['./number-percentage-dirty.component.scss'],
    imports: [ReactiveFormsModule, FormlyModule]
})
export class NumberPercentageDirtyComponent extends InputFieldType {

  get type() {
    return this.props.type || 'text';
  }

  get numberPercentageDirtyFormControl() {
    return this.formControl as FormControl;
  }
}
