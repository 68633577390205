import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import * as i4 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FieldType, FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
function FormlyFieldRadio_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "input", 3);
    i0.ɵɵelementStart(2, "label", 4);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const option_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("form-check-inline", ctx_r2.props.formCheck === "inline");
    i0.ɵɵadvance();
    i0.ɵɵclassProp("is-invalid", ctx_r2.showError);
    i0.ɵɵproperty("id", ctx_r2.id + "_" + i_r2)("name", ctx_r2.field.name || ctx_r2.id)("value", option_r1.value)("formControl", option_r1.disabled ? ctx_r2.disabledControl : ctx_r2.formControl)("formlyAttributes", ctx_r2.field);
    i0.ɵɵattribute("value", option_r1.value)("aria-describedby", ctx_r2.id + "-formly-validation-error")("aria-invalid", ctx_r2.showError);
    i0.ɵɵadvance();
    i0.ɵɵproperty("for", ctx_r2.id + "_" + i_r2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r1.label, " ");
  }
}
function FormlyFieldRadio_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, FormlyFieldRadio_ng_template_0_div_0_Template, 4, 14, "div", 1);
    i0.ɵɵpipe(1, "formlySelectOptions");
    i0.ɵɵpipe(2, "async");
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 4, i0.ɵɵpipeBind2(1, 1, ctx_r2.props.options, ctx_r2.field)));
  }
}
class FormlyFieldRadio extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        formCheck: 'default'
      }
    };
  }
  get disabledControl() {
    return new FormControl({
      value: this.formControl.value,
      disabled: true
    });
  }
}
FormlyFieldRadio.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldRadio_BaseFactory;
  return function FormlyFieldRadio_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldRadio_BaseFactory || (ɵFormlyFieldRadio_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldRadio)))(__ngFactoryType__ || FormlyFieldRadio);
  };
})();
FormlyFieldRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldRadio,
  selectors: [["formly-field-radio"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], ["class", "form-check", 3, "form-check-inline", 4, "ngFor", "ngForOf"], [1, "form-check"], ["type", "radio", 1, "form-check-input", 3, "id", "name", "value", "formControl", "formlyAttributes"], [1, "form-check-label", 3, "for"]],
  template: function FormlyFieldRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldRadio_ng_template_0_Template, 3, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.NgForOf, i2.RadioControlValueAccessor, i2.DefaultValueAccessor, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i1.AsyncPipe, i4.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldRadio, [{
    type: Component,
    args: [{
      selector: 'formly-field-radio',
      template: `
    <ng-template #fieldTypeTemplate>
      <div
        *ngFor="let option of props.options | formlySelectOptions : field | async; let i = index"
        class="form-check"
        [class.form-check-inline]="props.formCheck === 'inline'"
      >
        <input
          type="radio"
          [id]="id + '_' + i"
          class="form-check-input"
          [name]="field.name || id"
          [class.is-invalid]="showError"
          [attr.value]="option.value"
          [value]="option.value"
          [formControl]="option.disabled ? disabledControl : formControl"
          [formlyAttributes]="field"
          [attr.aria-describedby]="id + '-formly-validation-error'"
          [attr.aria-invalid]="showError"
        />
        <label class="form-check-label" [for]="id + '_' + i">
          {{ option.label }}
        </label>
      </div>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyBootstrapRadioModule {}
FormlyBootstrapRadioModule.ɵfac = function FormlyBootstrapRadioModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyBootstrapRadioModule)();
};
FormlyBootstrapRadioModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapRadioModule
});
FormlyBootstrapRadioModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'radio',
      component: FormlyFieldRadio,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapRadioModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldRadio],
      imports: [CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'radio',
          component: FormlyFieldRadio,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapRadioModule, FormlyFieldRadio };
