import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { ActiveStorageAttachmentType } from '@parashift/shared/types';

export interface  ActiveStorageAttachmentPlainModel {
  blob_signed_id: string;
  custom_input_file: boolean;
  custom_source_file: boolean;
  content_type: string;
  filename: string;
  name: string;
  record_id: number;
  record_type: 'Document' | 'Batch';
  rotate_by: number;
  url: string;
  container_file: ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>;
}

@Model({ type: ActiveStorageAttachmentType })
export class ActiveStorageAttachment extends BaseApiModel<ActiveStorageAttachmentPlainModel> {
  @Attribute()
  blob_signed_id: string;

  @Attribute()
  custom_input_file: boolean;

  @Attribute()
  custom_source_file: boolean;

  @Attribute({ readonly: true })
  content_type: string;

  @Attribute({ readonly: true })
  filename: string;

  @Attribute()
  name: string;

  @Attribute({ readonly: true })
  record_id: number;

  @Attribute({ readonly: true })
  record_type: 'Document' | 'Batch';

  @Attribute()
  rotate_by: 0 | 90 | 180 | -90;

  @Attribute({ readonly: true })
  url: string;

  @BelongsTo({ class: ActiveStorageAttachmentType, sidepostable: false, readonly: true })
  container_file: ActiveStorageAttachment | Partial<ActiveStorageAttachment>;
}
