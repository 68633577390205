import {
  fa0,
  faAddressBook,
  faAddressCard,
  faAmpersand,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faArchive,
  faArchway,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowFromBottom,
  faArrowFromTop,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowsLeftRight,
  faArrowsToCircle,
  faArrowsUpDownLeftRight,
  faArrowsV,
  faArrowToBottom,
  faArrowToLeft,
  faArrowToRight,
  faArrowTurnDownLeft,
  faAsterisk,
  faAt,
  faAxe,
  faBadgeCheck,
  faBadgePercent,
  faBallotCheck,
  faBars,
  faBoxBallot,
  faBrainCircuit,
  faBriefcase,
  faBrowser,
  faBullhorn,
  faBullseyePointer,
  faCalculatorSimple,
  faCalendarAlt,
  faCalendarExclamation,
  faCaretDown,
  faCaretUp,
  faCashRegister,
  faChalkboardTeacher,
  faChartBar,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCircleTrash,
  faCircleUser,
  faCircleX,
  faClipboardCheck,
  faClock,
  faClone,
  faCloudUpload,
  faCode,
  faCog,
  faCogs,
  faCommentAltLines,
  faConstruction,
  faCopy,
  faCreditCard,
  faDatabase,
  faDirections,
  faDisplay,
  faDotCircle,
  faDownload,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faExchange,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileChartLine,
  faFileChartPie,
  faFileCheck,
  faFileCode,
  faFileContract,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileJpg,
  faFileMedical,
  faFilePdf,
  faFilePowerpoint,
  faFileSearch,
  faFileTimes,
  faFileUpload,
  faFileUser,
  faFileWord,
  faFileZipper,
  faFilter,
  faFlag,
  faFolderGear,
  faFolderOpen,
  faFolderPlus,
  faFolderUser,
  faFolders,
  faGearCode,
  faGlobe,
  faGripLinesVertical,
  faHandHoldingSeedling,
  faHandPointer,
  faHandshake,
  faHeadSide,
  faHighlighterLine,
  faHistory,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faICursor,
  faInboxIn,
  faIndustryAlt,
  faInfinity,
  faInfoCircle,
  faInfoSquare,
  faInputText,
  faJedi,
  faKey,
  faLink,
  faList,
  faListCheck,
  faListUl,
  faLock,
  faLockOpen,
  faMailBulk,
  faMapMarkerAlt,
  faMapMarkerQuestion,
  faMessageSlash,
  faMinus,
  faNetworkWired,
  faNotEqual,
  faOctagonXmark,
  faPage,
  faPageBreak,
  faPaperPlane,
  faPaste,
  faPen,
  faPenField,
  faPercent,
  faPhoneSquare,
  faPiggyBank,
  faPipe,
  faPlus,
  faPlusCircle,
  faPlusMinus,
  faPollH,
  faPollPeople,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faRedo,
  faRulerHorizontal,
  faSave,
  faScalpelPath,
  faSearch,
  faSearchLocation,
  faServer,
  faShare,
  faShieldCheck,
  faSlashForward,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortDown,
  faSparkles,
  faSpellCheck,
  faSpinner,
  faSquare,
  faSquareDashed,
  faStarOfLife,
  faStopwatch,
  faSuitcase,
  faSyncAlt,
  faTable,
  faTachometerFast,
  faTachometerSlow,
  faTachometerSlowest,
  faTag,
  faTags,
  faTasks,
  faText,
  faTextSize,
  faTh,
  faThLarge,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashCheck,
  faTrashCanPlus,
  faTreeChristmas,
  faUndo,
  faUndoAlt,
  faUniversity,
  faUnlink,
  faUpload,
  faUser,
  faUserAlien,
  faUserChef,
  faUserCheck,
  faUserClock,
  faUserCog,
  faUserEdit,
  faUserHardHat,
  faUserHelmetSafety,
  faUserLock,
  faUserRobot,
  faUserTie,
  faUserTimes,
  faValueAbsolute,
  faWaveform,
  faWaveformLines,
  faWrench,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

export const icons = [
  fa0,
  faAddressBook,
  faAddressCard,
  faAmpersand,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faArchive,
  faArchway,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowFromBottom,
  faArrowFromTop,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowsLeftRight,
  faArrowsToCircle,
  faArrowsUpDownLeftRight,
  faArrowsV,
  faArrowToBottom,
  faArrowToLeft,
  faArrowToRight,
  faArrowTurnDownLeft,
  faAsterisk,
  faAt,
  faAxe,
  faBadgeCheck,
  faBadgePercent,
  faBallotCheck,
  faBars,
  faBoxBallot,
  faBrainCircuit,
  faBriefcase,
  faBrowser,
  faBullhorn,
  faBullseyePointer,
  faCalculatorSimple,
  faCalendarAlt,
  faCalendarExclamation,
  faCaretDown,
  faCaretUp,
  faCashRegister,
  faChalkboardTeacher,
  faChartBar,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronsLeft,
  faChevronsRight,
  faCircle,
  faCircleNotch,
  faCircleTrash,
  faCircleUser,
  faCircleX,
  faClipboardCheck,
  faClock,
  faClone,
  faCloudUpload,
  faCode,
  faCog,
  faCogs,
  faCommentAltLines,
  faConstruction,
  faCopy,
  faCreditCard,
  faDatabase,
  faDirections,
  faDisplay,
  faDotCircle,
  faDownload,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faExchange,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileChartLine,
  faFileChartPie,
  faFileCheck,
  faFileCode,
  faFileContract,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileJpg,
  faFileMedical,
  faFilePdf,
  faFilePowerpoint,
  faFileSearch,
  faFileTimes,
  faFileUpload,
  faFileUser,
  faFileWord,
  faFileZipper,
  faFilter,
  faFlag,
  faFolderGear,
  faFolderOpen,
  faFolderPlus,
  faFolderUser,
  faFolders,
  faGearCode,
  faGlobe,
  faGripLinesVertical,
  faHandHoldingSeedling,
  faHandPointer,
  faHandshake,
  faHeadSide,
  faHighlighterLine,
  faHistory,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faICursor,
  faInboxIn,
  faIndustryAlt,
  faInfinity,
  faInfoCircle,
  faInfoSquare,
  faInputText,
  faJedi,
  faKey,
  faLink,
  faList,
  faListCheck,
  faListUl,
  faLock,
  faLockOpen,
  faMailBulk,
  faMapMarkerAlt,
  faMapMarkerQuestion,
  faMessageSlash,
  faMinus,
  faNetworkWired,
  faNotEqual,
  faOctagonXmark,
  faPage,
  faPageBreak,
  faPaperPlane,
  faPaste,
  faPen,
  faPenField,
  faPercent,
  faPhoneSquare,
  faPiggyBank,
  faPipe,
  faPlus,
  faPlusCircle,
  faPlusMinus,
  faPollH,
  faPollPeople,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faRedo,
  faRulerHorizontal,
  faSave,
  faScalpelPath,
  faSearch,
  faSearchLocation,
  faServer,
  faShare,
  faShieldCheck,
  faSlashForward,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortDown,
  faSparkles,
  faSpellCheck,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faSquareDashed,
  faStarOfLife,
  faStopwatch,
  faSuitcase,
  faSyncAlt,
  faTable,
  faTachometerFast,
  faTachometerSlow,
  faTachometerSlowest,
  faTag,
  faTags,
  faTasks,
  faText,
  faTextSize,
  faTh,
  faThLarge,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashCheck,
  faTrashCanPlus,
  faTreeChristmas,
  faUndo,
  faUndoAlt,
  faUniversity,
  faUnlink,
  faUpload,
  faUser,
  faUserAlien,
  faUserChef,
  faUserCheck,
  faUserClock,
  faUserCog,
  faUserEdit,
  faUserHardHat,
  faUserHelmetSafety,
  faUserLock,
  faUserRobot,
  faUserTie,
  faUserTimes,
  faValueAbsolute,
  faWaveform,
  faWaveformLines,
  faWrench,
  faXmark,
];
