import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { ModalService } from 'shared/components/modals/modal.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-repeat-section',
    templateUrl: './repeat-section.component.html',
    imports: [NgFor, NgIf, NgClass, FaIconComponent, TooltipModule, FormlyModule]
})
export class RepeatSectionComponent extends FieldArrayType {

  renderingStartIndex = 0;
  hiddenFieldsCount = 0;
  hiddenFieldsCountTranslation = '';

  constructor(private cdRef: ChangeDetectorRef, private modalService: ModalService) {
    super();
  }

  removeSection(i) {
    if (this.props.onRemoveSection) {
      this.props.onRemoveSection(this.model[i], i);
    }
    this.remove(i);
  }

  confirmRemovingAllSections() {
    this.modalService.confirmation({
      initialState: {
        title: $localize `:@@shared.components.forms.repeat_section.delete_items:Delete items`,
        description: $localize `:@@shared.components.forms.repeat_section.delete_items_confirm:Do you want to delete all items?`
      }
    }).subscribe(() => {
      this.removeAllSections();
    });
  }

  private removeAllSections() {
    for (let i = this.model.length - 1; i >= 0; i--) {
      super.remove(i);
    }
  }

  moveUp(position) {
    let new_position = position - 1;

    if (position === 0) {
      new_position = this.formControl.length - 1;
    }

    this.reorder(position, new_position);
  }

  moveDown(position) {
    let new_position = position + 1;

    if (position === this.formControl.length - 1) {
      new_position = 0;
    }

    this.reorder(position, new_position);
  }

  add(i?: number, initialModel?: any): void {
    super.add(i, initialModel);
    this.cdRef.detectChanges();

    if (this.props.hooks && this.props.hooks.itemAdded) {
      const added = this.model[this.model.length - 1];
      this.props.hooks.itemAdded(added);
    }
  }

  remove(i: number): void {
    super.remove(i);
  }

  private reorder(oldPosition: number, newPosition: number) {
    const model = this.model[oldPosition];
    this.remove(oldPosition);
    this.add(newPosition, model);
  }
}
