import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TenantMembership } from '@parashift/shared/models';
import { SessionService } from '@parashift/shared/services';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-missing-configuration',
  templateUrl: './missing-configuration.component.html',
  imports: [NgIf, NgFor]
})
export class MissingConfigurationComponent implements OnInit {

  message: string;
  userTenants: TenantMembership[];

  reason = {
    1: $localize `:@@pages.components.missing_configuration.reason.1:Please ask your administrator to set a redirect after login.`,
    2: $localize `:@@pages.components.missing_configuration.reason.2:Something went wrong. Please ask Parashift for support.`,
    pdc: $localize `:@@pages.components.missing_configuration.reason.pdc:Please ask the owner of the tenant or an administrator to give you permissions for the Parashift Document Center Product`,
    invalid_url: $localize `:@@pages.components.missing_configuration.reason.invalid_url:You requested an invalid url`,
    error: $localize `:@@pages.components.missing_configuration.reason.error:An error occurred and we were not able to redirect you to the requested resource`
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params: ParamMap) => {
      if (params.get('reason')) {
        this.message = this.reason[params.get('reason')];
      } else {
        this.message = this.reason[2];
      }
    });

    this.userTenants = this.sessionService.user?.tenants.filter(tenant => tenant.permissions.length > 0) ?? [];
  }

  goToTenantPDC(tenantId: number) {
    this.router.navigate(['pdc/' + tenantId]);
  }
}
