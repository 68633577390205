import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, of } from 'rxjs';
import { BasicForm } from 'shared/components/forms/basic.form';
import {
  BusinessSector,
  DocumentTypeField,
  DocumentTypeFieldStatus,
  Locale,
  Visibility,
  Verifier,
  Transformer,
  OutputDataTypeLabels
} from '@parashift/shared/models';
import { BusinessSectorsRegister, CurrentTenantRegister, LocalesRegister } from '@parashift/shared/services';
import { getSelectboxOptionsFromConstants } from '@parashift/shared/utils';

export class EditFieldsetFieldForm extends BasicForm {
  verifiers: Verifier[] = [];
  transformers: Transformer[] = [];
  locales: Locale[] = [];
  businessSectors: BusinessSector[] = [];

  constructor(
    public model: DocumentTypeField['plainModel'],
    public options: FormlyFormOptions = {},
    transformers: Transformer[],
    verifiers: Verifier[],
    private localesRegister: LocalesRegister,
    private businessSectorsRegister: BusinessSectorsRegister,
    private currentTenantRegister: CurrentTenantRegister
  ) {
    super(model as DocumentTypeField['plainModel'], options);
    this.options.formState = { model: this.model, transformers, verifiers };
    this.transformers = transformers;
    this.verifiers = verifiers;
  }

  private initData() {
    this.localesRegister.originData.forEach((locale, index) => {
      if (!this.model.locales || !this.model.locales.find(l => l.code === locale.code)) {
        this.locales.push(this.localesRegister.data[index]);
      }
    });

    this.businessSectorsRegister.originData.forEach((businessSector, index) => {
      if (!this.model.business_sectors || !this.model.business_sectors.find(bs => bs.code === businessSector.code)) {
        this.businessSectors.push(this.businessSectorsRegister.data[index]);
      }
    });
  }

  initFields() {
    this.initData();

    this.fields = [
      {
        wrappers: ['card-wrapper'],
        props: {
          label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.general:General`
        },
        fieldGroup: [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-sm-6',
                key: 'title',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@common.title:Title`,
                  required: true
                }
              },
              {
                className: 'col-sm-6',
                key: 'tenant_id',
                type: 'input-display',
                props: {
                  label: $localize `:@@common.issuer:Issuer`,
                  displayValue: '',
                },
                expressionProperties: {
                  'props.displayValue': (model) => {
                    return model.parent_id || model.tenant_id;
                  }
                }
              },
              {
                className: 'col-sm-6',
                key: 'identifier',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@common.identifier:Identifier`,
                  required: true
                }
              },
              {
                className: 'col-sm-3',
                key: 'status',
                type: 'select',
                defaultValue: 'draft',
                props: {
                  label: $localize `:@@common.status:Status`,
                  options: getSelectboxOptionsFromConstants(DocumentTypeFieldStatus, false, undefined),
                  required: true
                }
              },
              {
                className: 'col-sm-3',
                key: 'visibility',
                type: 'select',
                props: {
                  label: $localize `:@@common.visibility:Visibility`,
                  options: [],
                  required: false
                },
                expressionProperties: {
                  'props.options': () => {
                    return getSelectboxOptionsFromConstants(Visibility, false, undefined, undefined, false)
                    .filter(option => {
                      if (option.label !== Visibility.public) {
                        return option;
                      } else {
                        const current_tenant = this.currentTenantRegister.tenant;

                        if (
                          current_tenant
                          && current_tenant.feature_flags
                          && Array.isArray(current_tenant.feature_flags)
                          && current_tenant.feature_flags.find(flag => flag === 'publisher')
                        ) {
                          return option;
                        } else {
                          return false;
                        }
                      }
                    });
                  }
                }
              },
              {
                className: 'col-sm-12',
                key: 'description',
                type: 'textarea',
                props: {
                  label: $localize `:@@common.description:Description`,
                  rows: 4,
                  required: false
                }
              },
              {
                className: 'col-sm-6',
                key: 'locales_placeholder',
                type: 'multi-select-typeahead',
                props: {
                  label: $localize `:@@common.locales:Locales`,
                  debounceTime: 100,
                  selectedModel: 'locales',
                  shownOption: 'search_index',
                  selectedOption: 'code',
                  tooltipOption: 'name',
                  icon: 'flag',
                  search: formControl => {
                    const term = formControl.value;
                    const query = new RegExp(term, 'ig');

                    return of(
                      this.locales.filter((item: any) => query.test(item.search_index))
                      );
                  },
                  onSelect: (event: TypeaheadMatch) => {
                    const index = this.locales.findIndex(locale => locale.code === event.item.code);

                    if (index > -1) {
                      this.locales.splice(index, 1);
                    }
                  },
                  onUnlink: (locale: Locale) => {
                    this.locales.push(this.localesRegister.getByCode(locale.code));
                  }
                },
                expressionProperties: {
                  'props.disabled': (model: any) => {
                    if (Array.isArray(model.locales) && model.locales.length === 3) {
                      return true;
                    }

                    return false;
                  }
                }
              },
              {
                className: 'col-sm-6',
                key: 'business_sectors_placeholder',
                type: 'multi-select-typeahead',
                props: {
                  label: $localize `:@@common.business_sectors:Business Sectors`,
                  debounceTime: 100,
                  selectedModel: 'business_sectors',
                  shownOption: 'search_index',
                  selectedOption: 'description',
                  sliceSelectedOption: 15,
                  tooltipOption: 'description',
                  icon: 'suitcase',
                  search: formControl => {
                    const term = formControl.value;
                    const query = new RegExp(term, 'ig');

                    return of(
                      this.businessSectors.filter((item: any) => query.test(item.search_index))
                    );
                  },
                  onSelect: (event: TypeaheadMatch) => {
                    const index = this.businessSectors.findIndex(bs => bs.code === event.item.code);

                    if (index > -1) {
                      this.businessSectors.splice(index, 1);
                    }
                  },
                  onUnlink: (bs: BusinessSector) => {
                    this.businessSectors.push(this.businessSectorsRegister.getByCode(bs.category, bs.code));
                  }
                },
                expressionProperties: {
                  'props.disabled': (model: any) => {
                    if (Array.isArray(model.business_sectors) && model.business_sectors.length === 3) {
                      return true;
                    }

                    return false;
                  }
                }
              },
              {
                className: 'col-sm-12',
                key: 'tags',
                type: 'tag-typeahead',
                props: {
                  type: 'text',
                  label: $localize `:@@common.tags:Tags`
                }
              }
            ]
          }
        ]
      },
      {
        wrappers: ['card-wrapper'],
        props: {
          label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.input:Input`
        },
        fieldGroup: [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-sm-6',
                key: 'extractor_attribute',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@common.extractor_attribute:Extractor Attribute`
                }
              },
              {
                className: 'col-sm-6',
                key: 'page_number',
                type: 'input',
                props: {
                  type: 'number',
                  label: $localize `:@@common.page_number:Page Number`,
                  min: 1
                }
              }
            ]
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-sm-6',
                key: 'minimum_date',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.minimum_date:Minimum date`,
                  mask: 'date'
                },
                hideExpression: (model: any) => {
                  return model.output_data_type !== 'date' && model.output_data_type !== 'datetime';
                },
                validators: {
                  validation: ['date']
                }
              },
              {
                className: 'col-sm-6',
                key: 'maximum_date',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.maximum_date:Maximum date`,
                  mask: 'date'
                },
                hideExpression: (model: any) => {
                  return model.output_data_type !== 'date' && model.output_data_type !== 'datetime';
                },
                validators: {
                  validation: ['date']
                }
              },
              {
                className: 'col-sm-6',
                key: 'minimum_integer',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.minimum_integer:Minimum integer`,
                  mask: 'integer'
                },
                hideExpression: (model: any) => {
                  return model.output_data_type !== 'integer' && model.output_data_type !== 'float';
                }
              },
              {
                className: 'col-sm-6',
                key: 'maximum_integer',
                type: 'input',
                props: {
                  type: 'text',
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.maximum_integer:Maximum integer`,
                  mask: 'integer'
                },
                hideExpression: (model: any) => {
                  return model.output_data_type !== 'integer' && model.output_data_type !== 'float';
                }
              },
              {
                className: 'col-sm-12',
                key: 'confidence_threshold',
                type: 'threshold-range-slider',
                props: {
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.confidence_thresholds:Extraction Thresholds`,
                }
              },
              {
                className: 'col-sm-12',
                key: 'recognition_threshold',
                type: 'recognition-slider',
                props: {
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.recognition_threshold:Recognition Threshold`,
                }
              },
              {
                className: 'col-sm-12 json-field',
                key: 'input_arguments',
                type: 'textarea',
                props: {
                  type: 'text',
                  label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.input_arguments:Input arguments`,
                  rows: 4
                },
                validators: {
                  validation: ['json']
                }
              },
            ]
          }
        ]
      },
      {
        wrappers: ['card-wrapper'],
        props: {
          label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.output:Output`
        },
        fieldGroup: [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-sm-6',
                key: 'output_data_type',
                type: 'select',
                defaultValue: 'string',
                props: {
                  label: $localize `:@@common.output_data_type:Output data type`,
                  options: getSelectboxOptionsFromConstants(OutputDataTypeLabels, false, undefined),
                  required: true
                }
              }
            ]
          }
        ]
      },
      {
        wrappers: ['card-wrapper'],
        props: {
          label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.verifiers_and_transformers:Verifiers & Transformers`
        },
        fieldGroup: [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-sm-6',
                key: 'verifiers',
                type: 'repeat-section',
                props: {
                  labelAdd: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.add_new_verifier:Add new verifier`,
                  btnClass: 'btn-xs'
                },
                fieldArray: {
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      className: 'col-sm-12',
                      key: 'identifier',
                      type: 'select',
                      props: {
                        label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.verifier:Verifier`,
                        valueProp: 'identifier',
                        labelProp: 'title',
                        options: new Observable((observer: Observer<any[]>) => {
                          const options = [{ identifier: null, title: '' }];
                          this.verifiers.forEach(verifier => options.push({
                            identifier: verifier.identifier,
                            title: `${verifier.title} (${verifier.status})`
                          }));
                          observer.next(options);
                          observer.complete();
                        }),
                        change: (field) => {
                          setTimeout(() => {
                            const currentVerifier = this.verifiers.find(verifier => verifier.identifier === field.model.identifier );
                            field.model.parameters = [];

                            if (currentVerifier && currentVerifier.parameters && Array.isArray(currentVerifier.parameters)) {
                              currentVerifier.parameters.forEach(param => {
                                field.model.parameters.push({
                                  [param.identifier]: '',
                                  data_type: param.data_type
                                });
                              });
                            }

                            field.parent.fieldGroup[1]['_componentRefs'][1].instance.changeFields(field.parent.fieldGroup[1]);
                          });
                        }
                      }
                    },
                    {
                      className: 'col-sm-12',
                      key: 'parameters',
                      type: 'pdc-dynamic-param-section',
                      props: {
                        model: 'verifiers',
                        childClass: 'col-sm-12'
                      },
                      fieldArray: {
                        fieldGroupClassName: 'row',
                        fieldGroup: []
                      }
                    }
                  ]
                }
              },
              {
                className: 'col-sm-6',
                key: 'transformers',
                type: 'repeat-section',
                props: {
                  labelAdd: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.add_new_transformer:Add new transformer`,
                  btnClass: 'btn-xs'
                },
                fieldArray: {
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      className: 'col-sm-12',
                      key: 'identifier',
                      type: 'select',
                      props: {
                        label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.transformer:Transformer`,
                        valueProp: 'identifier',
                        labelProp: 'title',
                        options: new Observable((observer: Observer<any[]>) => {
                          const options = [{ identifier: null, title: '' }];
                          this.transformers.forEach(transformer => options.push({
                            identifier: transformer.identifier,
                            title: `${transformer.title} (${transformer.status})`
                          }));
                          observer.next(options);
                          observer.complete();
                        }),
                        change: (field) => {
                          setTimeout(() => {
                            const currentTransformer = this.transformers.find(transformer => transformer.identifier === field.model.identifier );
                            field.model.parameters = [];

                            if (currentTransformer && currentTransformer.parameters && Array.isArray(currentTransformer.parameters)) {
                              currentTransformer.parameters.forEach(param => {
                                field.model.parameters.push({
                                  [param.identifier]: '',
                                  data_type: param.data_type
                                });
                              });
                            }

                            field.parent.fieldGroup[1]['_componentRefs'][1].instance.changeFields(field.parent.fieldGroup[1]);
                          });
                        }
                      }
                    },
                    {
                      className: 'col-sm-12',
                      key: 'parameters',
                      type: 'pdc-dynamic-param-section',
                      props: {
                        model: 'transformers',
                        childClass: 'col-sm-12'
                      },
                      fieldArray: {
                        fieldGroupClassName: 'row',
                        fieldGroup: []
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        wrappers: ['card-wrapper'],
        props: {
          label: $localize `:@@common.validation:Validation`
        },
        fieldGroup: [
          {
            wrappers: ['headline-wrapper'],
            className: 'col-sm-12',
            fieldGroup: [
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    className: 'col-sm-3',
                    key: 'optional',
                    type: 'checkbox',
                    defaultValue: false,
                    props: {
                      label: $localize `:@@common.optional:Optional?`,
                      indeterminate: false
                    }
                  },
                  {
                    className: 'col-sm-3',
                    key: 'not_for_validation',
                    type: 'checkbox',
                    defaultValue: false,
                    props: {
                      label: $localize `:@@common.not_for_validation:Not for validation`,
                      indeterminate: false
                    }
                  },
                  {
                    className: 'col-sm-3',
                    key: 'coordinates_required',
                    type: 'checkbox',
                    defaultValue: true,
                    props: {
                      label: $localize `:@@common.coordinates_required:Coordinates required`,
                      indeterminate: false
                    }
                  },
                  {
                    className: 'col-sm-3',
                    key: 'coordinates_free_floating',
                    type: 'checkbox',
                    defaultValue: false,
                    props: {
                      label: $localize `:@@common.coordinates_free_floating:Free-floating coordinates`,
                      indeterminate: false,
                      description: $localize `:@@common.coordinates_free_floating_description:Allow for annotating any region of document (useful when no token around region available).`
                    }
                  }
                ]
              }
            ]
          },
          {
            wrappers: ['headline-wrapper'],
            className: 'col-sm-12',
            props: {
              label: $localize `:@@common.help:Help`
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    className: 'col-sm-12',
                    key: 'validation_help',
                    type: 'textarea',
                    defaultValue: '',
                    props: {
                      label: $localize `:@@common.message:Message`,
                      description: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.validation_help_description:Help text displayed to users during validation.`,
                      rows: 2
                    }
                  }
                ]
              }
            ]
          },
          {
            wrappers: ['headline-wrapper'],
            className: 'col-sm-6',
            props: {
              label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.regex_warning_pattern:Regex Warning`
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    className: 'col-sm-6',
                    key: 'regex_warning_pattern',
                    type: 'input',
                    defaultValue: '',
                    props: {
                      type: 'text',
                      label: $localize `:@@common.pattern:Pattern`
                    }
                  },
                  {
                    className: 'col-sm-6',
                    key: 'regex_warning_hint',
                    type: 'input',
                    defaultValue: '',
                    props: {
                      type: 'text',
                      label: $localize `:@@common.message:Message`,
                      description: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.regex_hint_description:If value does not match pattern the associated message is shown.`
                    }
                  }
                ]
              }
            ]
          },
          {
            wrappers: ['headline-wrapper'],
            className: 'col-sm-6',
            props: {
              label: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.regex_error_pattern:Regex Error`
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    className: 'col-sm-6',
                    key: 'regex_error_pattern',
                    type: 'input',
                    defaultValue: '',
                    props: {
                      type: 'text',
                      label: $localize `:@@common.pattern:Pattern`
                    }
                  },
                  {
                    className: 'col-sm-6',
                    key: 'regex_error_hint',
                    type: 'input',
                    defaultValue: '',
                    props: {
                      type: 'text',
                      label: $localize `:@@common.message:Message`,
                      description: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.regex_hint_description:If value does not match pattern the associated message is shown.`
                    }
                  }
                ]
              }
            ]
          },
        ]
      }
    ] as FormlyFieldConfig[];
  }
}
