import { ConfigOption } from '@ngx-formly/core';

import { ButtonComponent } from './types/button/button.component';
import { CheckboxComponent } from './types/checkbox/checkbox.component';
import { DatepickerComponent } from './types/datepicker/datepicker.component';
import { DatetimepickerComponent } from './types/datetimepicker/datetimepicker.component';
import { DocumentSectionsMetaListComponent } from './types/pdc/document-sections-meta-list/document-sections-meta-list.component';
import { DocumentTypeFieldsMetaListComponent } from './types/pdc/document-type-fields-meta-list/document-type-fields-meta-list.component';
import { InputComponent } from './types/input/input.component';
import { InputDisplayComponent } from './types/input-display/input-display.component';
import { MessageComponent } from 'shared/components/forms/types/message/message.component';
import { MulticheckboxComponent } from './types/multicheckbox/multicheckbox.component';
import { MultiSelectboxComponent } from './types/pdc/multi-selectbox/multi-selectbox.component';
import { MultiSelectTypeaheadComponent } from './types/multi-select-typeahead/multi-select-typeahead.component';
import { NumberPercentageComponent } from './types/number-percentage/number-percentage.component';
import { NumberPercentageDirtyComponent } from './types/number-percentage-dirty/number-percentage-dirty.component';
import { PdcDynamicParamSectionComponent } from './types/pdc/pdc-dynamic-param-section/pdc-dynamic-param-section.component';
import { PdcFieldsetFieldSectionComponent } from './types/pdc/pdc-fieldset-field-section/pdc-fieldset-field-section.component';
import { PdcNameAndIdentifierComponent } from './types/pdc/pdc-name-and-identifier-input/pdc-name-and-identifier-input.component';
import { RecognitionSliderComponent } from './types/recognition-slider/recognition-slider.component';
import { RepeatSectionComponent } from './types/repeat-section/repeat-section.component';
import { SelectComponent } from './types/select/select.component';
import { SelectWithButtonComponent } from './types/select-with-button/select-with-button.component';
import { SwitchComponent } from './types/switch/switch.component';
import { TagTypeaheadComponent } from './types/tag-typeahead/tag-typeahead.component';
import { TemplateComponent } from './types/template/template.component';
import { ThresholdRangeSliderComponent } from './types/threshold-range-slider/threshold-range-slider.component';
import { TypeaheadComponent } from './types/typeahead/typeahead.component';

import { AddonsWrapperComponent } from './wrappers/addons-wrapper/addons-wrapper.component';
import { CardWrapperComponent } from './wrappers/card-wrapper/card-wrapper.component';
import { CollapsibleWrapperComponent } from './wrappers/collapsible-wrapper/collapsible-wrapper.component';
import { DocumentTypeFieldMetaBodyWrapperComponent } from './wrappers/pdc/document-type-field-meta-body-wrapper/document-type-field-meta-body-wrapper.component';
import { DocumentTypeFieldMetaHeadWrapperComponent } from './wrappers/pdc/document-type-field-meta-head-wrapper/document-type-field-meta-head-wrapper.component';
import { EmptyWrapperComponent } from './wrappers/empty-wrapper/empty-wrapper.component';
import { FieldWrapperComponent } from './wrappers/field-wrapper/field-wrapper.component';
import { HeadlineWrapperComponent } from './wrappers/headline-wrapper/headline-wrapper.component';
import { MulticheckboxWrapperComponent } from './wrappers/pdc/multicheckbox-wrapper/multicheckbox-wrapper.component';
import { RepeatSectionWrapperComponent } from './wrappers/repeat-section-wrapper/repeat-section-wrapper.component';

import { DatetimeValidator } from './validators/datetime/datetime.validator';
import { DateValidator } from './validators/date/date.validator';
import { EmailValidator } from './validators/email.validator';
import { JsonValidator } from 'shared/components/forms/validators/json.validator';
import { MimeTypeFormatValidator } from './validators/mime-type-format.validator';
import { ParameterNameValidator } from 'shared/components/forms/validators/parameter-name.validator';
import { AllowedCharactersValidatorFormly } from './validators/allowed-characters.validator';

import { DocumentTypeSectionConfiguratorComponent } from 'components/document-type-configurator/tabs/tab-fields/section-configurator/section-configurator.component';
import { DocumentTypeSectionsConfiguratorComponent } from 'components/document-type-configurator/tabs/tab-fields/sections-configurator/sections-configurator.component';
import { TenantTypeaheadComponent } from './types/tenant-typeahead/tenant-typeahead.component';

export const formConfig: ConfigOption = {
  types: [
    {
      name: 'input',
      component: InputComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    {
      name: 'number-percentage',
      component: NumberPercentageComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    {
      name: 'number-percentage-dirty',
      component: NumberPercentageDirtyComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    {
      name: 'input-display',
      component: InputDisplayComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    {
      name: 'typeahead',
      component: TypeaheadComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    {
      name: 'multi-select-typeahead',
      component: MultiSelectTypeaheadComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm typeahead-with-icon-search'
          }
        }
      }
    },
    {
      name: 'tag-typeahead',
      component: TagTypeaheadComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    {
      name: 'tenant-typeahead',
      component: TenantTypeaheadComponent,
      wrappers: ['form-field'],
    },
    {
      name: 'button',
      component: ButtonComponent,
      wrappers: ['form-field'],
      defaultOptions: {
        props: {
          btnType: 'default',
          type: 'button'
        }
      }
    },
    {
      name: 'pdc-name-and-identifier-input',
      component: PdcNameAndIdentifierComponent,
      wrappers: [],
      defaultOptions: {
        props: {
          attributes: {
            class: 'form-control form-control-sm'
          }
        }
      }
    },
    { name: 'select', component: SelectComponent, wrappers: ['form-field'] },
    { name: 'multi-selectbox', component: MultiSelectboxComponent, wrappers: ['form-field'] },
    { name: 'checkbox', component: CheckboxComponent, wrappers: ['form-field'] },
    { name: 'repeat-section', component: RepeatSectionComponent, wrappers: ['repeat-section-wrapper'] },
    { name: 'repeat-section-with-validation', component: RepeatSectionComponent, wrappers: ['form-field'] },
    { name: 'pdc-dynamic-param-section', component: PdcDynamicParamSectionComponent, wrappers: ['form-field'] },
    { name: 'datepicker-type', component: DatepickerComponent, wrappers: ['form-field'] },
    { name: 'datetimepicker-type', component: DatetimepickerComponent, wrappers: ['form-field'] },
    { name: 'multicheckbox', component: MulticheckboxComponent, wrappers: ['form-field'] },
    { name: 'switch', component: SwitchComponent, wrappers: ['form-field'] },
    { name: 'template', component: TemplateComponent },
    { name: 'message', component: MessageComponent, wrappers: [] },
    { name: 'document-type-section-configurator', component: DocumentTypeSectionConfiguratorComponent, wrappers: [] },
    { name: 'document-type-sections-configurator', component: DocumentTypeSectionsConfiguratorComponent, wrappers: [] },
    { name: 'threshold-range-slider', component: ThresholdRangeSliderComponent, wrappers: ['form-field'] },
    { name: 'recognition-slider', component: RecognitionSliderComponent, wrappers: ['form-field'] },
    { name: 'document-type-fields-meta-list', component: DocumentTypeFieldsMetaListComponent, wrappers: [] },
    { name: 'document-sections-meta-list', component: DocumentSectionsMetaListComponent, wrappers: [] },
    { name: 'select-with-button', component: SelectWithButtonComponent, wrappers: ['form-field'] },
    { name: 'pdc-fieldset-field-section', component: PdcFieldsetFieldSectionComponent, wrappers: [] },
  ],

  wrappers: [
    { name: 'addons', component: AddonsWrapperComponent },
    { name: 'card-wrapper', component: CardWrapperComponent },
    { name: 'collapsible-wrapper', component: CollapsibleWrapperComponent },
    { name: 'document-type-field-meta-body-wrapper', component: DocumentTypeFieldMetaBodyWrapperComponent },
    { name: 'document-type-field-meta-head-wrapper', component: DocumentTypeFieldMetaHeadWrapperComponent },
    { name: 'empty-wrapper', component: EmptyWrapperComponent },
    { name: 'form-field', component: FieldWrapperComponent },
    { name: 'headline-wrapper', component: HeadlineWrapperComponent },
    { name: 'multicheckbox-wrapper', component: MulticheckboxWrapperComponent },
    { name: 'repeat-section-wrapper', component: RepeatSectionWrapperComponent },
  ],

  validators: [
    { name: 'date', validation: DateValidator },
    { name: 'datetime', validation: DatetimeValidator },
    { name: 'email', validation: EmailValidator },
    { name: 'json', validation: JsonValidator },
    { name: 'mime-type-format', validation: MimeTypeFormatValidator },
    { name: 'parameter-name', validation: ParameterNameValidator },
    { name: 'allowed-characters', validation: AllowedCharactersValidatorFormly, options: { pattern: '^[a-zß-üA-ZÀ-Ü0-9-_.@+ ]+$' } },
  ]
};

export const formComponents = [
  // types
  ButtonComponent,
  CheckboxComponent,
  DatepickerComponent,
  DatetimepickerComponent,
  DocumentSectionsMetaListComponent,
  DocumentTypeFieldsMetaListComponent,
  DocumentTypeSectionConfiguratorComponent,
  DocumentTypeSectionsConfiguratorComponent,
  InputComponent,
  InputDisplayComponent,
  MessageComponent,
  MulticheckboxComponent,
  MultiSelectboxComponent,
  MultiSelectTypeaheadComponent,
  NumberPercentageComponent,
  NumberPercentageDirtyComponent,
  PdcDynamicParamSectionComponent,
  PdcFieldsetFieldSectionComponent,
  PdcNameAndIdentifierComponent,
  RecognitionSliderComponent,
  RepeatSectionComponent,
  SelectComponent,
  SelectWithButtonComponent,
  SwitchComponent,
  TagTypeaheadComponent,
  TemplateComponent,
  TenantTypeaheadComponent,
  ThresholdRangeSliderComponent,
  TypeaheadComponent,

  // wrappers
  AddonsWrapperComponent,
  CardWrapperComponent,
  CollapsibleWrapperComponent,
  DocumentTypeFieldMetaBodyWrapperComponent,
  DocumentTypeFieldMetaHeadWrapperComponent,
  EmptyWrapperComponent,
  FieldWrapperComponent,
  HeadlineWrapperComponent,
  MulticheckboxWrapperComponent,
  RepeatSectionWrapperComponent,
];
