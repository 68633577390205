<div>
  <ng-container *ngFor="let field of field.fieldGroup; let i = index">
    <div>
      <div *ngIf="!field.hide" class="repeat-section document-type-section card form-card">
        <div class="card-header" *ngIf="!model[i].fieldset_config_mode">
          <div class="limit-width">
            <div class="form-group section-title">
              <label [for]="'section-title-' + i" i18n="@@common.title">Title</label>
              <input [disabled]="!model[i].allow_edit" class="form-control" [(ngModel)]="model[i].title" [id]="'section-title-' + i">
            </div>

            <div class="repeat-section-remove">
              <button *ngIf="model[i].allow_edit"
                      type="button"
                      class="close"
                      aria-label="Remove"
                      (click)="removeSection(i)"
                      [disabled]="documentTypeHelperService.disableForm"
                      [hidden]="props.fieldsOnly === true">
                <fa-icon [icon]="['fas', 'times-circle']"
                        transform="shrink-3"
                        [fixedWidth]="true"
                        aria-hidden="true"
                        i18n-tooltip="@@common.remove"
                        tooltip="Remove"
                        container="body"
                        containerClass="danger">
                </fa-icon>
              </button>
              <button *ngIf="model[i].allow_edit && model.length > 1"
                      type="button"
                      class="close up"
                      aria-label="Move up"
                      (click)="moveUp(i)"
                      [disabled]="documentTypeHelperService.disableForm || i === 0">
                <fa-icon [icon]="['fas', 'chevron-circle-up']"
                        transform="shrink-3"
                        [fixedWidth]="true"
                        aria-hidden="true"
                        i18n-tooltip="@@common.move_up"
                        tooltip="Move up"
                        container="body">
                </fa-icon>
              </button>
              <button *ngIf="model[i].allow_edit && model.length > 1"
                      type="button"
                      class="close down"
                      aria-label="Move down"
                      (click)="moveDown(i)"
                      [disabled]="documentTypeHelperService.disableForm || i === model.length - 1">
                <fa-icon [icon]="['fas', 'chevron-circle-down']"
                        transform="shrink-3"
                        [fixedWidth]="true"
                        aria-hidden="true"
                        i18n-tooltip="@@common.move_down"
                        tooltip="Move down"
                        container="body">
                </fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <formly-field [field]="field"></formly-field>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="card form-card document-type-section" *ngIf="!props.labelAdd ? false : props.allowEdit()">
  <div class="card-body">
    <div class="d-flex" style="max-width: 790px">
      <div *ngIf="props.labelAdd" class="repeat-section-button" [hidden]="((props.maxItems || props.maxItems === 0) && props.maxItems <= model.length) || props.fieldsOnly === true">
        <button type="button" class="btn btn-dark" [ngClass]="props.btnClass ? props.btnClass : 'btn-sm'" (click)="add()" [disabled]="documentTypeHelperService.disableForm">{{ props.labelAdd }}</button>
      </div>
      <div *ngIf="model && model.length > 0 && props.showRemoveAllSectionsButton">
        <button type="button" class="btn btn-danger btn-sm" (click)="removeAllSections()" [disabled]="documentTypeHelperService.disableForm">{{ props.labelRemoveAllSections }}</button>
      </div>
    </div>
  </div>
</div>
