import { Component } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { CheckPermissionsDirective } from '@parashift/shared/directives';

@Component({
    selector: 'document-sections-meta-list',
    templateUrl: './document-sections-meta-list.component.html',
    styleUrls: ['./document-sections-meta-list.component.scss'],
    imports: [CheckPermissionsDirective, NgFor, NgIf, FormlyModule]
})
export class DocumentSectionsMetaListComponent extends FieldArrayType {}
