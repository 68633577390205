<hr class="separator" *ngIf="!config.fieldset_config_mode" />

<div class="row" *ngIf="!config.fieldset_config_mode && config.id">
  <div *ngIf="config.document_type_fieldset_id" class="col-sm-12">
    <div  style="margin-top: 14px;" *ngIf="!tenant_id; else noLink">
      <small>
        <a (click)="goToFieldset(config.document_type_fieldset_id)" class="pointer"><ng-container i18n="@@common.fieldset">Fieldset</ng-container>{{ config.fields && config.fields.length > 0 && config.fields[0].document_type_fieldset_name ? ': ' + config.fields[0].document_type_fieldset_name : '' }}
          {{ config.document_type_fieldset_id ? ' | id: ' + config.document_type_fieldset_id : '' }} {{ config.document_type_fieldset_root_id ? ' | root: ' + config.document_type_fieldset_root_id : '' }}
        </a>
      </small>
    </div>
    <ng-template #noLink>
      <div style="margin-top: 14px;">
        <small>
          <ng-container i18n="@@common.fieldset">Fieldset</ng-container>{{ config.fields && config.fields.length > 0 && config.fields[0].document_type_fieldset_name ? ': ' + config.fields[0].document_type_fieldset_name : '' }}
          {{ config.document_type_fieldset_id ? ' | id: ' + config.document_type_fieldset_id : '' }} {{ config.document_type_fieldset_root_id ? ' | root: ' + config.document_type_fieldset_root_id : '' }}
        </small>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="config.fields.length === 0; else fieldsTemplate" class="text-center">
  <div class="mt-4">
    <ng-container *ngIf="config.fieldset_config_mode" i18n="@@shared_pdc.components.document_type_configurator.fieldset_contains_no_fields">
      Fieldset contains no fields.
    </ng-container>
    <ng-container *ngIf="!config.fieldset_config_mode" i18n="@@shared_pdc.components.document_type_configurator.section_contains_no_fields">
      Section contains no fields.
    </ng-container>
  </div>
  <div *ngIf="config.id === undefined" i18n="@@shared_pdc.components.document_type_configurator.save_add_fields_hint">
    Save changes to be able to add fields.
  </div>
</div>

<ng-template #fieldsTemplate>

  <div class="row">
    <ng-container *ngIf="config?.master_data_config?.table_name">
      <div class="fields-row" [checkPermissions]="{ roles: ['admin', 'support', 'sales'], permissions: ['admin', 'owner', 'pdc.configure_doctype'] }">
        <div class="row">
          <div class="field-container">
            <div class="field-control">
              <label [for]="config?.master_data_config.table_name">{{ config?.master_data_config.name }}</label>
              <div class="fields-row-item">
                <div class="input-container">
                  <input type="text" class="form-control" [ngModel]="config?.master_data_config.table_name" readonly>
                </div>
                <div class="item-buttons">
                  <button type="button" class="btn btn-sm btn-dark" aria-label="Edit master data search configuration"
                          (click)="editMasterDataSearch(config.master_data_config)"
                          [disabled]="documentTypeHelperService.disableForm">
                    <fa-icon [icon]="['fas', 'wrench']"
                            [fixedWidth]="true"
                            aria-hidden="true"
                            i18n-tooltip="@@@@shared_pdc.components.document_type_configurator.configure_master_data_search" tooltip="Configure Master Data Search"
                            container="body">
                    </fa-icon>
                  </button>
                  <button type="button" class="btn btn-sm btn-danger" style="background-color: #f86c6b;
                  border-color: #f86c6b;" aria-label="Remove field"
                          (click)="deleteMasterDataSearch()"
                          [disabled]="documentTypeHelperService.disableForm">
                    <fa-icon [icon]="['fas', 'times-circle']"
                              [fixedWidth]="true"
                              aria-hidden="true"
                              i18n-tooltip="@@shared_pdc.components.document_type_configurator.remove_master_data_search" tooltip="Remove Master Data Search"
                              container="body"
                              containerClass="danger">
                    </fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let row of fields; let rowIndex = index">

      <div [hidden]="shouldHideRowBeforeDropzone(rowIndex)" class="placeholder-row mt-4"
           [dndDropzone] (dndDrop)="insertRowBefore($event.data, rowIndex)"
           i18n="@@shared_pdc.components.document_type_configurator.drop_here">
        Drop here
      </div>

      <div #fieldsRow class="fields-row">

        <div class="row">
          <div *ngFor="let field of row; let fieldIndex = index" class="col-sm-{{field.positioning.columns}} mt-3 fields-row-col"
               [dndDropzone] (dndDragover)="onFieldDragover($event, rowIndex, fieldIndex)">

            <div class="field-container">

              <div [hidden]="shouldHideFieldBeforeDropzone(rowIndex, fieldIndex)" class="placeholder-field pe-2 ps-2 me-2"
                   [dndDropzone] (dndDrop)="insertFieldBefore($event.data, rowIndex, fieldIndex)"
                   i18n="@@shared_pdc.components.document_type_configurator.drop">
                Drop
              </div>

              <div class="field-control">

                <label [title]="field.title" [for]="field.identifier + '_' + rowIndex">{{ field.title }}</label>

                <div class="fields-row-item" #fieldItem
                     [dndDraggable]="{ fieldId: field.id, fieldIndex: fieldIndex, rowIndex: rowIndex, type: 'reorder' }"
                     (dndStart)="onFieldDragStart($event, field)" (dndEnd)="onFieldDragEnd()">

                  <!-- unreachable dndHandle placeholder to disable drag and drop -->
                  <span *ngIf="!config.allow_edit" dndHandle></span>

                  <div *ngIf="config.allow_edit && field.positioning.columns > 3" class="item-buttons" [ngClass]="{ 'dragged': draggedFieldId === field.id }">
                    <button type="button" class="btn btn-sm btn-secondary move-btn" dndHandle>
                      <fa-icon [icon]="['fas', 'arrows-alt']"
                               [fixedWidth]="true">
                      </fa-icon>
                    </button>
                  </div>
                  <div *ngIf="field.positioning.columns > 3" class="input-container" [ngClass]="{ 'dragged': draggedFieldId === field.id }">
                    <input [type]="'text'" (click)="onFieldClick(field)" class="form-control" [ngModel]="field.output_data_type" readonly [id]="field.identifier + '_' + rowIndex">
                  </div>
                  <div *ngIf="config.allow_edit && field.positioning.columns > 3" class="item-buttons" [ngClass]="{ 'dragged': draggedFieldId === field.id }">
                    <ng-container *ngIf="config.allow_llm_config && config.document_type_fieldset_id">
                      <button type="button" class="btn btn-sm btn-dark border-end border-white" aria-label="Edit AI Config"
                              (click)="configureLlmConfig(config.id)"
                              [disabled]="documentTypeHelperService.disableForm">
                        <span i18n-tooltip="@@@@shared_pdc.components.document_type_configurator.edit_ai_config" tooltip="Edit AI config" container="body">
                          <svg xmlns="http://www.w3.org/2000/svg" class="fa-icon" aria-hidden="true" focusable="false" viewBox="0 0 640 512"><path fill="currentColor" d="M0 444.1c0-18 7.2-35.3 19.9-48L201.5 214.5c-6.1-17-9.5-35.4-9.5-54.5C192 71.6 263.6 0 352 0c15.3 0 30.1 2.2 44.2 6.2c10.7 3.1 13.2 16.4 5.3 24.3l-76.8 76.8c-3 3-4.7 7.1-4.7 11.3l0 57.4c0 8.8 7.2 16 16 16l57.4 0c4.2 0 8.3-1.7 11.3-4.7l76.8-76.8c7.9-7.9 21.2-5.5 24.3 5.3c4 14.1 6.2 28.9 6.2 44.2c0 11.5-1.2 22.8-3.5 33.6c-4-1-8.2-1.6-12.5-1.6c-20.6 0-39 12.8-46.2 32l-26.7 71.1L357 319.9c-1.7 .1-3.3 .1-5 .1c-19.1 0-37.5-3.3-54.5-9.5L115.9 492.1c-12.7 12.7-30 19.9-48 19.9C30.4 512 0 481.6 0 444.1zM56 432c0 13.3 10.7 24 24 24s24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24zm296-64c0-7.2 4.5-13.7 11.2-16.2L448 320l31.8-84.8c2.5-6.7 9-11.2 16.2-11.2s13.7 4.4 16.2 11.2L544 320l84.8 31.8c6.7 2.5 11.2 9 11.2 16.2s-4.4 13.7-11.2 16.2L544 416l-31.8 84.8c-2.5 6.7-9 11.2-16.2 11.2s-13.7-4.4-16.2-11.2L448 416l-84.8-31.8c-6.7-2.5-11.2-9-11.2-16.2z"/></svg>
                        </span>
                      </button>
                    </ng-container>
                    <button *ngIf="field.allow_edit && tenant_id === field.tenant_id && config.document_type_fieldset_id" type="button" class="btn btn-sm btn-dark" aria-label="Edit field"
                            (click)="configureField(field)"
                            [disabled]="documentTypeHelperService.disableForm"
                            [checkPermissions]="{ roles: ['admin', 'support', 'sales'], permissions: [] }">
                      <fa-icon [icon]="['fas', 'wrench']"
                              [fixedWidth]="true"
                              aria-hidden="true"
                              i18n-tooltip="@@common.configure_field" tooltip="Configure field"
                              container="body">
                      </fa-icon>
                    </button>
                    <button *ngIf="field.allow_edit && tenant_id === field.tenant_id && !config.document_type_fieldset_id" type="button" class="btn btn-sm btn-dark" aria-label="Edit field"
                            (click)="configureField(field)"
                            [disabled]="documentTypeHelperService.disableForm">
                      <fa-icon [icon]="['fas', 'wrench']"
                               [fixedWidth]="true"
                               aria-hidden="true"
                               i18n-tooltip="@@common.configure_field" tooltip="Configure field"
                               container="body">
                      </fa-icon>
                    </button>
                    <button type="button" class="btn btn-sm btn-danger" style="background-color: #f86c6b;
                    border-color: #f86c6b;" aria-label="Remove field"
                            (click)="deleteDocumentTypeField(field.document_type_relation_id)"
                            [disabled]="documentTypeHelperService.disableForm">
                      <fa-icon [icon]="['fas', 'times-circle']"
                               [fixedWidth]="true"
                               aria-hidden="true"
                               i18n-tooltip="@@common.remove_field" tooltip="Remove field"
                               container="body"
                               containerClass="danger">
                      </fa-icon>
                    </button>
                  </div>

                  <div *ngIf="field.positioning.columns <= 3" class="field-dropdown">
                    <button class="btn btn-sm btn-secondary" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            (click)="toggleFieldDropdown(field)" dndDragImageRef [disabled]="!config.allow_edit"
                            [ngClass]="{ 'dragged': draggedFieldId === field.id, 'dropdown-toggle': config.allow_edit && field.allow_edit  }">
                      <span i18n="@@common.field">Field</span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                         [ngClass]="{ 'show': toggledFieldId === field.id }">
                      <button type="button" class="btn btn-sm btn-secondary move-btn" dndHandle>
                        <fa-icon [icon]="['fas', 'arrows-alt']"
                                 [fixedWidth]="true">
                        </fa-icon>
                        <ng-container i18n="@@shared_pdc.components.document_type_configurator.move">Move</ng-container>
                      </button>
                      <ng-container *ngIf="config.allow_llm_config && config.document_type_fieldset_id">
                        <button type="button" class="btn btn-sm btn-dark border-end border-white" aria-label="Remove"
                                (click)="configureLlmConfig(config.id)"
                                [disabled]="documentTypeHelperService.disableForm">
                          <span i18n-tooltip="@@@@shared_pdc.components.document_type_configurator.edit_ai_config" tooltip="Edit AI config" container="body">
                            <svg xmlns="http://www.w3.org/2000/svg" class="fa-icon" aria-hidden="true" focusable="false" viewBox="0 0 640 512"><path fill="currentColor" d="M0 444.1c0-18 7.2-35.3 19.9-48L201.5 214.5c-6.1-17-9.5-35.4-9.5-54.5C192 71.6 263.6 0 352 0c15.3 0 30.1 2.2 44.2 6.2c10.7 3.1 13.2 16.4 5.3 24.3l-76.8 76.8c-3 3-4.7 7.1-4.7 11.3l0 57.4c0 8.8 7.2 16 16 16l57.4 0c4.2 0 8.3-1.7 11.3-4.7l76.8-76.8c7.9-7.9 21.2-5.5 24.3 5.3c4 14.1 6.2 28.9 6.2 44.2c0 11.5-1.2 22.8-3.5 33.6c-4-1-8.2-1.6-12.5-1.6c-20.6 0-39 12.8-46.2 32l-26.7 71.1L357 319.9c-1.7 .1-3.3 .1-5 .1c-19.1 0-37.5-3.3-54.5-9.5L115.9 492.1c-12.7 12.7-30 19.9-48 19.9C30.4 512 0 481.6 0 444.1zM56 432c0 13.3 10.7 24 24 24s24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24zm296-64c0-7.2 4.5-13.7 11.2-16.2L448 320l31.8-84.8c2.5-6.7 9-11.2 16.2-11.2s13.7 4.4 16.2 11.2L544 320l84.8 31.8c6.7 2.5 11.2 9 11.2 16.2s-4.4 13.7-11.2 16.2L544 416l-31.8 84.8c-2.5 6.7-9 11.2-16.2 11.2s-13.7-4.4-16.2-11.2L448 416l-84.8-31.8c-6.7-2.5-11.2-9-11.2-16.2z"/></svg>
                          </span>
                          <ng-container i18n="@@shared_pdc.components.document_type_configurator.ai">Configure AI</ng-container>
                        </button>
                      </ng-container>
                      <button *ngIf="field.allow_edit && tenant_id === field.tenant_id && config.document_type_fieldset_id" type="button" class="btn btn-sm btn-dark" aria-label="Remove"
                              (click)="configureField(field)"
                              [disabled]="documentTypeHelperService.disableForm"
                              [checkPermissions]="{ roles: ['admin', 'support', 'sales'], permissions: [] }">
                        <fa-icon [icon]="['fas', 'wrench']"
                                 [fixedWidth]="true"
                                 aria-hidden="true"
                                 i18n-tooltip="@@common.configure_field" tooltip="Configure field"
                                 container="body">
                        </fa-icon>
                        <ng-container i18n="@@shared_pdc.components.document_type_configurator.configure">Configure</ng-container>
                      </button>
                      <button *ngIf="field.allow_edit && tenant_id === field.tenant_id && !config.document_type_fieldset_id" type="button" class="btn btn-sm btn-dark" aria-label="Remove"
                              (click)="configureField(field)"
                              [disabled]="documentTypeHelperService.disableForm">
                        <fa-icon [icon]="['fas', 'wrench']"
                                 [fixedWidth]="true"
                                 aria-hidden="true"
                                 i18n-tooltip="@@common.configure_field" tooltip="Configure field"
                                 container="body">
                        </fa-icon>
                        <ng-container i18n="@@shared_pdc.components.document_type_configurator.configure">Configure</ng-container>
                      </button>
                      <button type="button" class="btn btn-sm btn-danger" aria-label="Remove"
                              (click)="deleteDocumentTypeField(field.document_type_relation_id)"
                              [disabled]="documentTypeHelperService.disableForm">
                        <fa-icon [icon]="['fas', 'times-circle']"
                                 [fixedWidth]="true"
                                 aria-hidden="true"
                                 i18n-tooltip="@@common.remove_field" tooltip="Remove field"
                                 container="body"
                                 containerClass="danger">
                        </fa-icon>
                        <ng-container i18n="@@shared_pdc.components.document_type_configurator.remove">Remove</ng-container>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div *ngIf="fieldIndex !== row.length - 1" class="grip-handle"
                   [dndDraggable]="{ fieldId: field.id, fieldIndex: fieldIndex, rowIndex: rowIndex, type: 'resize' }"
                   (dndStart)="gripFieldHorizontally($event, fieldsRow.clientWidth, row, field, rowIndex)"
                   (dndDrag)="gripFieldHorizontally($event, fieldsRow.clientWidth, row, field, rowIndex)"
                   (dndEnd)="finishGrip()">
                <fa-icon [icon]="['fas', 'grip-lines-vertical']"
                         [fixedWidth]="true"
                         aria-hidden="true">
                </fa-icon>
              </div>

              <div [hidden]="shouldHideFieldAfterDropzone(rowIndex, fieldIndex)" class="placeholder-field pe-2 ps-2 ms-2"
                   [dndDropzone] (dndDrop)="insertFieldAfter($event.data, rowIndex, fieldIndex)"
                   i18n="@@shared_pdc.components.document_type_configurator.drop">
                Drop
              </div>

            </div>

          </div>
        </div>
      </div>

      <div [hidden]="shouldHideRowAfterDropzone(rowIndex)" class="placeholder-row mt-4"
           [dndDropzone] (dndDrop)="insertRowAfter($event.data, rowIndex)"
           i18n="@@shared_pdc.components.document_type_configurator.drop_here">
        Drop here
      </div>

    </ng-container>

  </div>

</ng-template>
<div class="container-fluid">
<div class="row" *ngIf="!config.fieldset_config_mode && config.id">
  <div class="col-sm-12">
    <div class="row">
      <div class="col">
        <div class="form-check" style="margin-top: 12px;">
          <input class="form-check-input" type="checkbox"
                 [(ngModel)]="config.highlight_row"
                 [class.is-invalid]="false"
                 [indeterminate]="false"
                 [disabled]="!config.allow_edit"
                 [id]="'highlight-row-' + config.id"
                 (ngModelChange)="highlightRowStateChanged($event)">
          <label class="form-check-label user-select-none" [for]="'highlight-row-' + config.id" i18n="@@shared_pdc.components.document_type_configurator.highlight_rows" i18n-tooltip="@@shared_pdc.components.document_type_configurator.tooltip_highlight_rows" tooltip="Highlight every row with a box around all fields." container="body">
            Highlight Rows?
          </label>
        </div>
      </div>
      <div class="col">
        <div class="form-check" style="margin-top: 12px;">
          <input class="form-check-input" type="checkbox"
                 [(ngModel)]="config.highlight_column"
                 [class.is-invalid]="false"
                 [indeterminate]="false"
                 [disabled]="!config.allow_edit"
                 [id]="'highlight-column-' + config.id"
                 (ngModelChange)="highlightColumnStateChanged($event)">
          <label class="form-check-label user-select-none" [for]="'highlight-column-' + config.id" i18n="@@shared_pdc.components.document_type_configurator.highlight_columns" i18n-tooltip="@@shared_pdc.components.document_type_configurator.tooltip_highlight_columns" tooltip="Highlight every column of fields with a different color." container="body">
            Highlight Columns?
          </label>
        </div>
      </div>
      <div *ngIf="sessionService.beta_features.table_input" class="col text-end">
        <select class="form-select form-select-sm mt-2" style="max-width: 150px; background-color: #c2cfd6; border-color: #c2cfd6;" [(ngModel)]="config.show_as_table" [disabled]="!config.repeatable || !config.allow_edit">
          <option [ngValue]="false"><ng-container *ngIf="!config.repeatable || !config.show_as_table; else notSelectedFields" i18n="@@shared_pdc.components.document_type_configurator.display_as_fields">Display as: Fields</ng-container><ng-template i18n="@@shared_pdc.components.document_type_configurator.fields" #notSelectedFields>Fields</ng-template></option>
          <option [ngValue]="true"><ng-container *ngIf="config.repeatable && config.show_as_table; else notSelectedTable" i18n="@@shared_pdc.components.document_type_configurator.display_as_table">Display as: Table</ng-container><ng-template i18n="@@shared_pdc.components.document_type_configurator.table" #notSelectedTable>Table</ng-template></option>
        </select>
      </div>
      <div class="col-auto">
        <div class="form-check" style="margin-top: 12px;">
          <input class="form-check-input" type="checkbox"
                 [(ngModel)]="config.repeatable"
                 [class.is-invalid]="false"
                 [indeterminate]="false"
                 [disabled]="!config.allow_edit || (config.fields.length > 1 && !config.document_type_fieldset_id)"
                 [id]="'repeatable-section-' + config.id"
                 (ngModelChange)="repeatableStateChanged($event)">
          <label class="form-check-label user-select-none" [for]="'repeatable-section-' + config.id" i18n="@@shared_pdc.components.document_type_configurator.repeatable">
            Repeatable
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="row text-center">
  <div *ngIf="config.id !== undefined && config.allow_edit" class="config-buttons-group mt-3" role="group" aria-label="Basic example">
    <button *ngIf="!config.document_type_fieldset_id"
            type="button"
            class="btn btn-sm btn-dark"
            (click)="addDocumentTypeField()"
            [disabled]="documentTypeHelperService.disableForm"
            i18n="@@shared_pdc.components.document_type_configurator.add_field">Add field</button>

    <button *ngIf="config.document_type_fieldset_id && !config.fieldset_config_mode"
            type="button"
            class="btn btn-sm btn-dark"
            (click)="openAddRemovedFieldsetFields(config)"
            [disabled]="documentTypeHelperService.disableForm"
            i18n="@@shared_pdc.components.document_type_configurator.reapply_field">Re-Apply Field</button>

    <button *ngIf="config.fields.length > 0 && !config.fieldset_config_mode && !config.repeatable"
            type="button"
            class="btn btn-sm btn-dark"
            (click)="editMasterDataSearch(config.master_data_config)"
            [disabled]="documentTypeHelperService.disableForm || config.master_data_config?.table_name"
            [checkPermissions]="{ roles: ['admin', 'support', 'sales'], permissions: ['admin', 'owner', 'pdc.configure_doctype'] }"
            i18n="@@shared_pdc.components.document_type_configurator.add_master_data_search">Add Master Data Search</button>
    <ng-container *ngIf="config.fields.length === 0 && !config.document_type_fieldset_id && !config.fieldset_config_mode">
      <button type="button"
              class="btn btn-sm btn-dark"
              (click)="openApplyDocumentTypeFieldsetModal()"
              [disabled]="documentTypeHelperService.disableForm"
              i18n="@@shared_pdc.components.document_type_configurator.apply_fieldset">Apply fieldset</button>
    </ng-container>
  </div>
  <button *ngIf="config.id === undefined && config.allow_edit"
          type="button"
          class="btn btn-sm btn-success mt-3 w-100"
          (click)="saveConfiguration()"
          [disabled]="documentTypeHelperService.disableForm"
          i18n="@@shared_pdc.components.document_type_configurator.save">Save</button>
</div>
