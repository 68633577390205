import { Routes } from '@angular/router';

import { canActivateAuth } from './guards/can-activate-auth';
import { canMatchAuth } from './guards/can-match-auth';
import { canMatchPdc } from './guards/can-match-pdc';
import { canDeactivatePdc } from './guards/can-deactivate-pdc';
import { canMatchMdt } from './guards/can-match-mdt';
import { canDeactivateMdt } from './guards/can-deactivate-mdt';

import { SimplePageComponent } from './layout/templates/simple-page/simple-page.component';
import { PageNotFoundComponent } from './views/pages/page-not-found/page-not-found.component';
import { HomeComponent } from './views/pages/home/home.component';
import { SessionCallbackComponent } from './views/session/callback/callback.component';
import { MissingConfigurationComponent } from './views/pages/missing-configuration/missing-configuration.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'session/callback',
    component: SessionCallbackComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      noRedirect: true
    }
  },
  {
    path: 'missing-configuration',
    component: MissingConfigurationComponent,
    data: {
      noRedirect: true
    }
  },
  {
    path: 'mdt',
    loadChildren: () => import('./views/mdt/mdt.routes'),
    canMatch: [canMatchAuth, canMatchMdt],
    canDeactivate: [canDeactivateMdt],
    data: {
      roles: ['admin', 'support', 'sales', 'engineering', 'billing']
    }
  },
  {
    path: 'pdc/:tenant_id',
    loadChildren: () => import('./views/pdc/pdc.routes'),
    canMatch: [canMatchAuth, canMatchPdc],
    canActivate: [canActivateAuth],
    canDeactivate: [canDeactivatePdc]
  },
  {
    path: '**',
    component: SimplePageComponent,
    children: [
      {
        path: '**',
        component: PageNotFoundComponent,
        data: {
          breadcrumb: '404'
        }
      }
    ]
  },
];
