import { registerLocaleData } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import { bootstrapApplication } from '@angular/platform-browser';
import { init, makeFetchTransport, makeMultiplexedTransport } from '@sentry/angular';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, deLocale } from 'ngx-bootstrap/locale';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import * as packageJson from '../../../package.json';
import { environment } from './environments/environment';

if (environment.enable_sentry) {
  const { version } = packageJson;

  init({
    dsn: environment.endpoints.global.sentry,
    transport: makeMultiplexedTransport(makeFetchTransport, ({ getEvent }) => {
      const event = getEvent();
      const region = event?.tags?.region || '';
      const dsn = region && region !== 'ZZ' ? environment.endpoints.sentry.replace('{region}', '-' + String(region)) : environment.endpoints.global.sentry;

      return [{
        dsn,
        environment: environment.env,
        release: 'app-parashift-io@' + version,
        attachStacktrace: true,
        ignoreErrors: [
          'Non-Error exception captured'
        ],
        debug: false
      }];
    })
  });
}

registerLocaleData(localeDeCh, 'de-CH');
defineLocale('engb', enGbLocale);
defineLocale('de', deLocale);

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
