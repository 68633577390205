import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { map, Observable, of, skipWhile, switchMap, tap } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MdmConfig } from '@parashift/shared/models';
import { SelectOption } from '@parashift/shared/utils';
import { InputFieldComponent } from 'shared/components/forms/form-controls/input-field/input-field.component';
import { SelectFieldComponent } from 'shared/components/forms/form-controls/select-field/select-field.component';
import { SqliteInitService } from 'components/master-data-management/services/sqlite-init.service';
import { MdmConfigService } from 'components/document-type-configurator/services/mdm-config.service';
import { DocumentTypeFieldConfig } from 'components/document-type-configurator/tabs/tab-fields/document-type-config';
import { TypeaheadFieldComponent } from 'shared/components/forms/form-controls/typeahead-field/typeahead-field.component';
import { emptyFormGroupValidator } from './empty-form-group.validator';

@Component({
  selector: 'add-mdm-config-modal',
  templateUrl: './add-mdm-config-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    InputFieldComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    SelectFieldComponent,
    TypeaheadFieldComponent,
  ],
  providers: [MdmConfigService]
})
export class AddMdmConfigModalComponent implements OnInit {

  state: boolean;
  model: MdmConfig;
  fields: DocumentTypeFieldConfig[];
  form: FormGroup;
  tableNameOptions: SelectOption[];
  tableColumns = [];
  documentTypeFieldOptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private sqliteInitService: SqliteInitService,
    private mdmConfigService: MdmConfigService,
    private cdr: ChangeDetectorRef,
    @Inject(DestroyRef) private destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.sqliteInitService.init();
    this.sqliteInitService.databaseEstablished$
      .pipe(
        skipWhile(state => state !== true),
        switchMap(() => this.setTableOptions()),
        tap(() => {
          this.initDocumentTypeFieldOptions();
          this.initForm();
          this.initTableChangeListener();
          this.cdr.detectChanges();
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  searchFields = (formControl: FormControl) => {
    const term = '' + formControl.value;
    return term
      ? of(this.documentTypeFieldOptions.filter(option => option.label.includes(term)))
      : of(this.documentTypeFieldOptions);
  }

  translateInitialFieldValueToLabel = (valueProp: any): Observable<any> => {
    const option = this.documentTypeFieldOptions.find(option => option.value === valueProp);
    return of(option || {});
  }

  confirm() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.state = true;
      this.bsModalRef.content.model.name = this.form.get('name').value;
      this.bsModalRef.content.model.table_name = this.form.get('table_name').value;
      this.bsModalRef.content.model.field_mapping = this.form.get('field_mapping').value;
      this.form.reset();
      this.bsModalRef.hide();
    } else {
      return;
    }
  }

  cancel() {
    this.state = false;
    this.bsModalRef.hide();
  }

  private setTableOptions() {
    return this.mdmConfigService.getTableNames().pipe(
      map(result => {
        const options = result.rows.map(row => ({ label: row['name'], value: row['name'] }));
        options.unshift({ label: '', value: '' });
        return options;
      }),
      tap(result => this.tableNameOptions = result)
    );
  }

  private initDocumentTypeFieldOptions() {
    if (this.fields.length > 0) {
      this.documentTypeFieldOptions = [];
      this.fields.forEach(field => this.documentTypeFieldOptions.push({ label: field.title + ' | ' + field.identifier + ' | ' + field.output_data_type, value: field.id }));
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      name: [this.model.name ?? '', Validators.required],
      table_name: [this.model.table_name ?? '', Validators.required]
    });

    if (this.model.field_mapping) {
      this.mdmConfigService.getTableColumns(this.model.table_name)
        .pipe(map(result => result.rows))
        .subscribe(result => {
          if (this.form.get('field_mapping')) {
            this.form.removeControl('field_mapping');
          }
          this.tableColumns = result;
          const fieldMapping = this.formBuilder.group({});
          this.form.addControl('field_mapping', fieldMapping);
          this.tableColumns.forEach(column => fieldMapping.addControl(column.name, this.formBuilder.control(this.model.field_mapping[column.name])));
          this.form.get('field_mapping').addValidators(emptyFormGroupValidator);
          this.form.get('field_mapping').updateValueAndValidity();
          this.cdr.detectChanges();
        });
    }
  }

  private initTableChangeListener() {
    this.form.get('table_name').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((name: string) => {
        this.mdmConfigService.getTableColumns(name)
          .pipe(map(result => result.rows))
          .subscribe(result => {
            if (this.form.get('field_mapping')) {
              this.form.removeControl('field_mapping');
            }
            this.tableColumns = result;
            const fieldMapping = this.formBuilder.group({});
            this.form.addControl('field_mapping', fieldMapping);
            this.tableColumns.forEach(column => fieldMapping.addControl(column.name, this.formBuilder.control('')));
            this.form.get('field_mapping').addValidators(emptyFormGroupValidator);
            this.form.get('field_mapping').updateValueAndValidity();
            this.cdr.detectChanges();
          }
        );
      });
  }
}
