import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emptyFormGroupValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const keys = Object.keys(control.value);
  if (keys.length === 0) {
    return null;
  } else {
    let empty = true;
    for (const key of keys) {
      if (control.value[key]) {
        empty = false;
        break;
      }
    }
    return empty ? { 'empty-form-group': true } : null;
  }
};
