import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'button-type',
    templateUrl: './button.component.html',
    imports: [NgClass, NgIf, FaIconComponent]
})
export class ButtonComponent extends FieldType<FieldTypeConfig> {
  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event, this);
    }
  }
}
