import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectOptionsPipe } from '../../pipes/select-options.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'select-with-button',
    templateUrl: './select-with-button.component.html',
    imports: [ReactiveFormsModule, FormlyModule, NgIf, NgFor, NgClass, FaIconComponent, AsyncPipe, FormlySelectOptionsPipe]
})
export class SelectWithButtonComponent extends FieldType<FieldTypeConfig> {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        class: 'form-control form-control-sm'
      }
    }
  };

  onSelectionChange(model) {
    if (this.props.hooks && this.props.hooks.selectionChange) {
      this.props.hooks.selectionChange(model);
    }
  }

  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event, this);
    }
  }

  get selectFormControl(): FormControl {
    return this.formControl as FormControl;
  }
}
