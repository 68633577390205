import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CheckPermissionsDirective } from '@parashift/shared/directives';
import { ModalService } from 'shared/components/modals/modal.service';
import { TemplateDocumentTypeFieldConfig } from 'components/document-type-configurator/tabs/tab-advanced/document-type-advanced.config';
import { DropdownOptionSettings, ParashiftDropdownComponent } from 'components/extraction-rules/pp-dropdown/pp-dropdown.component';

@Component({
  selector: 'document-type-field-meta-head-wrapper',
  templateUrl: './document-type-field-meta-head-wrapper.component.html',
  styleUrls: ['./document-type-field-meta-head-wrapper.component.scss'],
  imports: [
    CheckPermissionsDirective,
    FaIconComponent,
    FormsModule,
    NgIf,
    ParashiftDropdownComponent,
    ReactiveFormsModule,
    RouterLink,
    TooltipModule,
  ]
})
export class DocumentTypeFieldMetaHeadWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;

  templateField: TemplateDocumentTypeFieldConfig;

  dropdownOptions: DropdownOptionSettings[] = [
    {
      label: 'Extraction rules',
      click: () => this.router.navigate(['extraction-rules', this.model.document_type_relation_id], { relativeTo: this.route })
    },
    {
      label: 'Extraction rules root',
      click: () => this.router.navigate(['extraction-rules', this.model.document_type_relation_id, 'root'], { relativeTo: this.route })
    }
  ];

  isRootField: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private modalService: ModalService) {
    super();
  }

  ngOnInit() {
    this.templateField = this.model.parent_field || this.model.original_field;
    this.isRootField = this.model.id === ('' + this.model.root_id);
    this.props.reloadSlider({ id: this.model.id, disabled: !this.model.allow_workflow_changes || this.model.use_standard });
  }

  confirmUseStandardModal(event) {
    if (event) {
      this.modalService.confirmation({
        initialState: {
          title: $localize `:@@shared.components.forms.document_type_field_meta_head_wrapper_component.use_standard.title:Use standard?`,
          description: $localize `:@@shared.components.forms.document_type_field_meta_head_wrapper_component.use_standard.description:Your custom changes to this field in this Document Type will be lost. Are you sure you want to revert to the standard values?`,
          emitCancelEvent: true
        }
      }).subscribe(response => {
        this.setDefaultValues(response as boolean);
      });

    } else {
      this.setDefaultValues(event);
    }
  }

  private setDefaultValues(event: boolean) {
    if (event && this.templateField && this.model.allow_workflow_changes) {
      this.model.identifier = this.templateField.identifier;
      this.model.title = this.templateField.title;
      this.model.optional = this.templateField.optional;
      this.model.not_for_validation = this.templateField.not_for_validation;
      this.model.not_for_training = this.templateField.not_for_training;
      this.model.confidence_threshold[0] = this.templateField.confidence_threshold[0];
      this.model.confidence_threshold[1] = this.templateField.confidence_threshold[1];
      this.model.validation_help = this.templateField.validation_help;

      this.form.get('identifier').setValue(this.templateField.identifier, { emitEvent: true, emitModelToViewChange: true });
      this.form.get('title').setValue(this.templateField.title, { emitEvent: true, emitModelToViewChange: true });
      this.form.get('optional').setValue(this.templateField.optional, { emitEvent: true, emitModelToViewChange: true });
      this.form.get('not_for_validation').setValue(this.templateField.not_for_validation, { emitEvent: true, emitModelToViewChange: true });
      this.form.get('not_for_training').setValue(this.templateField.not_for_training, { emitEvent: true, emitModelToViewChange: true });
      this.form.get('confidence_threshold').setValue(this.templateField.confidence_threshold, { emitEvent: true, emitModelToViewChange: true });
      this.form.get('validation_help').setValue(this.templateField.validation_help, { emitEvent: true, emitModelToViewChange: true });
    } else if (event && this.templateField && !this.model.allow_workflow_changes) {
      this.model.identifier = this.templateField.identifier;
      this.form.get('identifier').setValue(this.templateField.identifier, { emitEvent: true, emitModelToViewChange: true });
    } else if (!event) {
      this.model.use_standard = false;
    }

    if (this.model.allow_workflow_changes) {
      this.props.reloadSlider({ id: this.model.id, disabled: event });
    }
  }
}
