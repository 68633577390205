import { Component } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { FieldArrayType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

import { registerControl, unregisterControl} from '../utils/utils';
import { NgFor } from '@angular/common';

@Component({
    selector: 'pdc-dynamic-param-section',
    templateUrl: './pdc-dynamic-param-section.component.html',
    imports: [NgFor, FormlyModule]
})
export class PdcDynamicParamSectionComponent extends FieldArrayType {

  onPopulate(field: FormlyFieldConfig) {
    if (!field.formControl && field.key) {
      registerControl(field, new UntypedFormArray([], { updateOn: field.modelOptions.updateOn }));
    }

    field.fieldGroup = field.fieldGroup || [];

    if (field.fieldGroup.length > 0) {
      for (let i = field.fieldGroup.length - 1; i >= length; --i) {
        if (field.fieldGroup[i].formControl) {
          unregisterControl(field.fieldGroup[i]);
        }
        field.fieldGroup.splice(i, 1);
      }
    }

    this.prepareFieldGroup(field);
  }

  rebuildForm(field: FormlyFieldConfig) {
    (field.options as any).build();
    field.formControl.markAsDirty();
  }

  changeFields(field) {
    if (field.fieldGroup.length > 0) {
      for (let i = field.fieldGroup.length - 1; i >= length; --i) {
        unregisterControl(field.fieldGroup[i]);
        field.fieldGroup.splice(i, 1);
      }
    }

    if (field.parent.model.identifier) {
      this.prepareFieldGroup(field);
      this.rebuildForm(field);
    }
  }

  prepareFieldGroup(field) {
    field.fieldGroup = [];

    if (
      field.parent
      && field.parent.model
      && field.parent.model.parameters
      && field.parent.model.parameters.length > 0
    ) {
      const item = field.options.formState[field.props.model].find(model => model.identifier === field.parent.model.identifier);

      if (
        item
        && item.parameters
        && Array.isArray(item.parameters)
        && item.parameters.length > 0
      ) {

        item.parameters.forEach((param, index) => {
          const groupField = {
            className: field.props.childClass || 'col-sm-6',
            key: param.identifier,
            type: 'input',
            props: {
              type: 'text',
              label: param.label + ' (' + param.data_type + ')'
            }
          };

          if (param.data_type === 'float') {
            groupField.props['mask'] = 'decimal';
          } else if (param.data_type === 'int') {
            groupField.props['mask'] = 'integer';
          }

          const fieldGroupItem = {
            fieldGroupClassName: 'row',
            fieldGroup: [groupField]
          };

          const f = { ...fieldGroupItem, key: `${index}` };
          field.fieldGroup.push(f);
        });
      }
    }
  }
}
