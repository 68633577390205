import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-simple-page',
  templateUrl: './simple-page.component.html',
  imports: [RouterOutlet]
})
export class SimplePageComponent implements OnDestroy {
  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'simple-page');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'simple-page');
  }
}
