import { Injectable } from '@angular/core';
import { SqliteWorkerService } from 'components/master-data-management/services/sqlite-worker.service';

@Injectable()
export class MdmConfigService {

  constructor(private sqliteWorkerService: SqliteWorkerService) {}

  getTableNames() {
    const statement = `SELECT name FROM sqlite_master
                       WHERE type='table' AND name NOT LIKE 'sqlite_%'
                       ORDER BY name;`;
    return this.sqliteWorkerService.exec(statement);
  }

  getTableColumns(tableName: string) {
    const statement = `SELECT * FROM PRAGMA_TABLE_INFO('${tableName}');`;
    return this.sqliteWorkerService.exec(statement);
  }
}
